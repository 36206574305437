import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import { COLORS, MobileSize } from '../../helpers/constants/styleguide';
import Icon, { Checkmark, Close, Caution, WarningIcon } from '../Icon';
import { Text } from '../typography';
import Link from '../typography/Link';
import Button from '../Button';
import AlertContainer from './AlertContainer';
import { FlexRow } from '../Flex';

const colors = {
  'success': COLORS.jade,
  'info': COLORS.azure,
  'caution': COLORS.gold,
  'warning': COLORS.orange,
  'alt-warning': COLORS.cobalt,
  'neutral': COLORS.darkGrey,
};

const svgComponents = {
  'success': Checkmark,
  'info': Caution,
  'caution': Caution,
  'warning': WarningIcon,
  'alt-warning': WarningIcon,
  'neutral': Caution,
};

const ActionButton = ({ callToAction }) => (
  <Button
    width={callToAction.buttonWidth || 150}
    onClick={callToAction.onClick}
    disabled={callToAction.buttonDisabled}
    fakeDisabled={callToAction.fakeDisabled}
    kind={callToAction.label}
    isLoading={callToAction.isLoading}
    type={callToAction.type}
  >
    <ButtonContent>
      {callToAction.label}
      {callToAction.icon}
    </ButtonContent>
  </Button>
);

const AlertEnd = ({ callToAction }) => {
  if (callToAction) {
    const { link, href, fullWidthMobileButton } = callToAction;
    const linkProps = {
      to: link,
      href,
    };

    return (
      <ButtonContainer fullWidthMobileButton={fullWidthMobileButton}>
        {linkProps ? (
          <Link {...linkProps}>
            <ActionButton callToAction={callToAction} />
          </Link>
        ) : (
          <ActionButton callToAction={callToAction} />
        )}
      </ButtonContainer>
    );
  }
  return null;
};

const Alert = ({
  theme = 'info',
  dismissable = true,
  externalDismissed,
  onClose,
  children,
  callToAction,
  showIcon = true,
  customIcon,
  centerContent = false,
  className,
  testId,
}) => {
  const [dismissed, setDismissed] = useState(false);

  const dismiss = () => {
    if (onClose) {
      onClose();
    }
    setDismissed(true);
  };

  const showClose = get(callToAction, 'onClick') ? false : dismissable;

  return (
    <AlertContainer
      dismissed={dismissed || externalDismissed}
      color={colors[theme]}
      className={className}
      testId={testId}
    >
      <AlertBody dismissable={dismissable} centerContent={centerContent}>
        {showIcon &&
          (customIcon || (
            <ThemeIcon
              width={18}
              height={18}
              SvgComponent={svgComponents[theme]}
              className={theme}
              // TODO: Remove below condition once success icon has been updated to Streamline
              fill={theme === 'success' ? null : colors[theme]}
              stroke={theme === 'success' ? colors[theme] : null}
            />
          ))}
        <Text paragraph small data-testid="alert-text">
          {children}
        </Text>
      </AlertBody>
      <AlertEnd callToAction={callToAction} dismissable={dismissable} />
      {showClose && (
        <CloseIcon
          onClick={dismiss}
          fill={COLORS.medGrey}
          hoverFill={COLORS.darkerGrey}
          width={10}
          SvgComponent={Close}
        />
      )}
    </AlertContainer>
  );
};

const ThemeIcon = styled(Icon)`
  margin-right: 8px;
  align-self: flex-start;
`;

const CloseIcon = styled(Icon)`
  justify-content: flex-end;
  align-self: flex-start;
  cursor: pointer;
  position: absolute;
  top: 12px;
  right: 12px;
`;

const AlertBody = styled.div`
  display: flex;
  flex: 1 1 auto;
  ${({ centerContent }) =>
    centerContent ? 'justify-content: center; text-align: center;' : ''}
  margin-right: ${({ dismissable }) => (dismissable ? '16px' : '0')};
`;

const ButtonContainer = styled.div`
  margin: 0 0 0 16px;

  @media (max-width: ${MobileSize}) {
    margin: 16px 0 0 auto;
    ${({ fullWidthMobileButton }) =>
      fullWidthMobileButton ? 'margin: 16px 0 0 0; width: 100%;' : null}
  }
`;

const ButtonContent = styled(FlexRow)`
  justify-content: center;

  .icon {
    margin-left: 5px;
  }
`;

Alert.propTypes = {
  children: PropTypes.node.isRequired,
  theme: PropTypes.oneOf([
    'info',
    'success',
    'caution',
    'warning',
    'alt-warning',
    'neutral',
  ]),
  dismissable: PropTypes.bool,
  callToAction: PropTypes.object,
  showIcon: PropTypes.bool,
  customIcon: PropTypes.node,
  centerContent: PropTypes.bool,
};

export { default as AlertContainer } from './AlertContainer';
export default Alert;
