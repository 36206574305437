import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { isFunction, isNil, isEmpty } from 'lodash';

import Platform, { MOBILE } from '../../helpers/hoc/Platform';
import { COLORS, MobileSize } from '../../helpers/constants/styleguide';
import { hexToRgba } from '../../helpers/utils/common';
import { dollars } from '../../helpers/utils/numbers';

import ColorBadge from '../ColorBadge';
import Text from '../typography/Text';
import { TextTooltip } from '../tooltipTriggers';
import AnimatedNumber from '../AnimatedNumber';

const FancyCard = ({
  children,
  headerInfo = [],
  title,
  shortTitle,
  color = 'magenta',
  backgroundStyle = 'none',
  headerBorderWidth,
  testId,
  CustomLeftHeader,
  headerDirection = 'row',
  platformType,
  className,
  hideHeader,
}) => {
  return (
    <FancyCardContainer data-testid={testId} className={className}>
      {!hideHeader && (
        <FancyHeader
          title={title}
          shortTitle={shortTitle}
          headerInfo={headerInfo}
          color={color}
          backgroundStyle={backgroundStyle}
          headerBorderWidth={headerBorderWidth}
          CustomLeftHeader={CustomLeftHeader}
          headerDirection={headerDirection}
          platformType={platformType}
        />
      )}
      <FancyContent>{children}</FancyContent>
    </FancyCardContainer>
  );
};

const FancyHeader = ({
  headerInfo,
  shortTitle,
  title,
  color,
  backgroundStyle,
  headerBorderWidth,
  CustomLeftHeader,
  headerDirection,
  platformType,
}) => {
  return (
    <FancyHeaderContainer
      color={color}
      backgroundStyle={backgroundStyle}
      headerBorderWidth={headerBorderWidth}
      headerDirection={headerDirection}
      className="FancyCardHeader"
    >
      <HeaderLeft
        shortTitle={shortTitle}
        title={title}
        CustomLeftHeader={CustomLeftHeader}
        color={color}
        headerInfo={headerInfo}
      />
      <HeaderComponents headerDirection={headerDirection}>
        {headerInfo.map(
          ({ title, value, valueColor, tooltipText, animate }) =>
            !isNil(value) && (
              <HeaderInfo
                key={title}
                title={title}
                value={value}
                backgroundStyle={backgroundStyle}
                valueColor={valueColor}
                headerDirection={headerDirection}
                tooltipText={tooltipText}
                animate={animate}
                platformType={platformType}
              />
            ),
        )}
      </HeaderComponents>
    </FancyHeaderContainer>
  );
};

const HeaderInfo = React.memo(function HeaderInfo({
  value,
  title,
  backgroundStyle,
  valueColor = COLORS.azure,
  headerDirection,
  tooltipText,
  animate,
  platformType,
}) {
  const dollarValue = dollars(value, { showSymbol: false });

  return (
    <HeaderInfoContainer headerDirection={headerDirection}>
      <Text as="span" size="extraSmall" paragraph>
        {isFunction(title) ? title() : title}
      </Text>
      {tooltipText ? (
        <HeaderInfoValue
          backgroundStyle={backgroundStyle}
          valueColor={valueColor}
        >
          <span className="symbol">$</span>
          <TextTooltip text={tooltipText}>
            {animate ? (
              <AnimatedNumber
                fontSize={platformType === MOBILE ? 24 : 32}
                formatValue={value => dollars(value, { showSymbol: false })}
                value={value}
                stepPrecision={0}
              />
            ) : (
              dollarValue
            )}
          </TextTooltip>
        </HeaderInfoValue>
      ) : (
        <HeaderInfoValue
          backgroundStyle={backgroundStyle}
          valueColor={valueColor}
        >
          <span className="symbol">$</span>
          {animate ? (
            <AnimatedNumber
              fontSize={platformType === MOBILE ? 24 : 32}
              formatValue={value => dollars(value, { showSymbol: false })}
              value={value}
              stepPrecision={0}
            />
          ) : (
            dollarValue
          )}
        </HeaderInfoValue>
      )}
    </HeaderInfoContainer>
  );
});

const HeaderLeft = ({
  CustomLeftHeader,
  title,
  shortTitle,
  color,
  headerInfo,
}) => {
  if (CustomLeftHeader) {
    return <CustomLeftHeader />;
  }
  const noHeaderInfo = isEmpty(headerInfo);

  return title ? (
    <BadgeContainer noHeaderInfo={noHeaderInfo}>
      <ColorBadge colorName={color} shortLabel={shortTitle}>
        {title}
      </ColorBadge>
    </BadgeContainer>
  ) : null;
};

const FancyContent = ({ children }) => {
  return <FancyContentContainer>{children}</FancyContentContainer>;
};

const solidHeaderOpacity = 0.13;
const lightHeaderOpacity = 0.06;
const stripeHeaderOpacity = 0.1;

const FancyCardContainer = styled.div`
  box-shadow: 0 2px 4px 0 #e7ebf1;
  background-color: ${COLORS.lightestGrey};
  border-radius: 6px;
  border: none;
`;

const stripBackground = color => css`
  background-image: repeating-linear-gradient(
    -45deg,
    ${COLORS.white},
    ${COLORS.white} 5px,
    ${hexToRgba(COLORS[color], stripeHeaderOpacity)} 5px,
    ${hexToRgba(COLORS[color], stripeHeaderOpacity)} 10px /* determines size */
  );
`;

const headerBottomBorder = (color, width) => css`
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: ${COLORS[color]};
  height: 4px;
  width: ${width}%;
`;

const FancyHeaderContainer = styled.div`
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  padding: 20px 24px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  align-items: center;
  border: none;
  overflow: hidden;

  // css for when headerDirection is column
  ${({ headerDirection }) => {
    if (headerDirection === 'column') {
      return css`
        flex-direction: ${headerDirection};
        & > *:first-child {
          margin-bottom: 12px;
        }
        align-items: flex-start;
      `;
    }
    return null;
  }};

  // handle background style
  ${({ backgroundStyle, color }) => {
    if (backgroundStyle === 'none') {
      return `background-color: ${hexToRgba(COLORS.lighterGrey, 0.6)}`;
    }
    if (backgroundStyle === 'solid') {
      return `background-color: ${hexToRgba(
        COLORS[color],
        solidHeaderOpacity,
      )}`;
    }
    if (backgroundStyle === 'light') {
      return `background-color: ${hexToRgba(
        COLORS[color],
        lightHeaderOpacity,
      )}`;
    }
    if (backgroundStyle === 'stripes') {
      return stripBackground(color);
    }
    return null;
  }};

  //handle header bottom border
  &:after {
    ${({ headerBorderWidth, color }) => {
      if (headerBorderWidth) {
        return headerBottomBorder(color, headerBorderWidth);
      }
      return null;
    }};
  }
`;

const BadgeContainer = styled.div`
  margin-right: 8px;
  overflow: hidden;
  flex: ${({ noHeaderInfo }) => (noHeaderInfo ? 'auto' : 1.6)};

  @media (max-width: ${MobileSize}) {
    margin-right: 16px;
  }
`;

const HeaderComponents = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: 2;

  & > * {
    margin-right: 24px;
    :last-child {
      margin-right: 0;
    }
  }

  // css for when headerDirection is column
  ${({ headerDirection }) => {
    if (headerDirection === 'column') {
      return css`
        align-self: flex-end;
      `;
    }
    return null;
  }};
`;

const HeaderInfoContainer = styled.div`
  text-align: right;

  @media (max-width: ${MobileSize}) {
    display: flex;
    flex-direction: column;

    // css for when headerDirection is column
    ${({ headerDirection }) => {
      if (headerDirection === 'column') {
        return css`
          flex-direction: row;
          align-items: center;
          & > *:first-child {
            margin-right: 8px;
          }
        `;
      }
      return null;
    }};
  }
`;

const HeaderInfoValue = styled.span`
  font-size: 32px;
  font-weight: bold;
  color: ${({ valueColor }) => valueColor};

  @media (max-width: ${MobileSize}) {
    font-size: 24px;
  }

  .symbol {
    font-size: 18px;
    vertical-align: super;
    @media (max-width: ${MobileSize}) {
      font-size: 12px;
    }
  }
`;

const FancyContentContainer = styled.div`
  padding: 24px 24px 32px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  background-color: ${COLORS.white};
  border: none;

  @media (max-width: ${MobileSize}) {
    padding-bottom: 24px;
  }
`;

FancyCard.propTypes = {
  children: PropTypes.element,
  color: PropTypes.oneOf([
    'gold',
    'magenta',
    'cobalt',
    'violet',
    'raven',
    'azure',
    'sand',
  ]),
  backgroundStyle: PropTypes.oneOf(['none', 'solid', 'stripes', 'light']),
  headerBorderWidth: PropTypes.number,
  title: PropTypes.string,
  shortTitle: PropTypes.string,
  // CustomLeftHeader will replace ColorBudge if we pass
  CustomLeftHeader: PropTypes.elementType,
  /** The default for valueColor is azure */
  headerInfo: PropTypes.arrayOf(
    PropTypes.shape({
      animate: PropTypes.bool,
      title: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.elementType,
        PropTypes.element,
      ]).isRequired,
      tooltipText: PropTypes.string,
      value: PropTypes.number.isRequired,
      valueColor: PropTypes.string,
    }).isRequired,
  ),
  headerDirection: PropTypes.oneOf(['row', 'column']),
};

export default Platform(FancyCard);
