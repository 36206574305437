import { findIndex, isEmpty } from 'lodash';
import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import {
  Header,
  Text,
  Strong,
  LoadingBars,
  Alert,
  COLORS,
} from '@summer/ui-components';

import ManageLoans from 'components/common/ManageLoans';
import { ManageLoansButton } from 'components/common/SyncLoans/Buttons';
import ConnectFsa from 'components/common/SyncLoans/ConnectFsa';
import LastUpdatedFsaLoans from 'components/common/ViewLoans/LoansTables/LastUpdatedFsaLoans';
import useScrollToErrorRow from 'components/common/ViewLoans/LoansTables/hooks/useScrollToErrorRow';
import ServicersLoansTables from 'components/common/ViewLoans/ServicersLoansTables';
import IdrAlerts from 'components/pages/idr/wizard/steps/YourLoans/IdrAlerts';
import PerkinsAlert from 'components/pages/idr/wizard/steps/YourLoans/PerkinsAlert';
import PayOff from 'components/pages/loans/components/PayOff';
import { programTypes } from 'constants/manageLoans';
import { MobileSize } from 'constants/styleguide';
import {
  fetchLoanTypes,
  fetchRepaymentPlanTypes,
} from 'redux/actions/constants.actions';
import { fetchLoans } from 'redux/actions/loans.actions';
import { fetchSyncStatus } from 'redux/actions/sync.actions';
import { openEditALoan } from 'redux/actions/ui.actions';
import {
  getUserFederalLoans,
  getFederalLoanTypes,
  getRepaymentPlanTypes,
  getActiveFsaLoanServicerNames,
  getInactiveFsaLoanServicerNames,
  getCanEditField,
} from 'redux/selectors/loans.selectors';
import { isLoading } from 'redux/selectors/ui.selectors';
import { getIdrLoanSchema } from 'schemas/loans';
import { getNormalizedFsaLoans, areLoansValid } from 'utils/loans';

const syncHeaderText = (
  <Text paragraph>
    We need accurate information about your loans to determine which repayment
    plans you are eligible for.{' '}
    <Strong>Connect your loans to help us find the right plan for you.</Strong>
  </Text>
);

const LoansList = ({
  fetchLoans,
  fetchLoanTypes,
  fetchRepaymentPlanTypes,
  fetchSyncStatus,
  openEditALoan,
  loansLoading,
  idrHandlers: { setFieldValue, submitForm },
  values,
  errors,
  touched,
  idrHandlers,
  isValid,
  servicersList,
  notActiveServicersList,
  userFederalLoans,
  federalLoanTypes,
  repaymentPlanTypes,
  canEditField,
  showMissingInfoHeader,
}) => {
  const formik = { values, errors, touched, ...idrHandlers };

  useEffect(() => {
    fetchLoans();
    fetchLoanTypes();
    fetchRepaymentPlanTypes();
    fetchSyncStatus();
  }, [fetchLoans, fetchLoanTypes, fetchRepaymentPlanTypes, fetchSyncStatus]);

  const userNormalizedFederalLoans = useMemo(() => {
    return loansLoading
      ? []
      : getNormalizedFsaLoans(
          userFederalLoans,
          getIdrLoanSchema(federalLoanTypes, repaymentPlanTypes, canEditField),
        );
  }, [
    userFederalLoans,
    federalLoanTypes,
    repaymentPlanTypes,
    canEditField,
    loansLoading,
  ]);

  useEffect(() => {
    const isUserLoansValid = areLoansValid(userNormalizedFederalLoans);
    setFieldValue('yourLoans.isValid', isUserLoansValid);
  }, [setFieldValue, userNormalizedFederalLoans]);

  const handleRowClick = normalizedLoans => loanId => {
    const loanIndex = findIndex(normalizedLoans, { id: loanId });
    openEditALoan(loanIndex, programTypes.idr);
  };

  const normalizedLoans = userNormalizedFederalLoans;

  const scrollToError = useScrollToErrorRow(normalizedLoans, { idr: true });

  const renderUserLoans = () => {
    const headerText = 'Your Federal Student Loans';
    const isLoansEmpty = isEmpty(userNormalizedFederalLoans);

    const renderSyncButton = !loansLoading && !isLoansEmpty && (
      <ButtonContainer>
        <ManageLoansButton enableAddALoan />
      </ButtonContainer>
    );

    const columnsToShow = [
      'loanType',
      'educationLevel',
      'repaymentPlan',
      'interestRate',
      'currentBalance',
    ];

    const renderLoans = (
      <>
        {areLoansValid(normalizedLoans) ? null : (
          <Alert dismissable={false} theme="warning">
            Some of your loan information didn’t come through correctly. Select
            your loans to edit them.
          </Alert>
        )}
        <br />
        <ServicersLoansTables
          normalizedLoans={normalizedLoans}
          allServicers={servicersList}
          active={true}
          columnsToShow={columnsToShow}
          onClickTableRow={handleRowClick(normalizedLoans)}
        />
        <PayOff openByDefault={true} hide={isEmpty(notActiveServicersList)}>
          <ServicersLoansTables
            normalizedLoans={normalizedLoans}
            allServicers={notActiveServicersList}
            active={false}
            columnsToShow={columnsToShow}
            onClickTableRow={handleRowClick(normalizedLoans)}
            tableName="paid-off"
          />
        </PayOff>
      </>
    );

    return (
      <>
        {isLoansEmpty && !loansLoading ? (
          <ConnectFsa
            page="idr"
            headerSize="h2"
            headerContent={syncHeaderText}
            headerImgPath="/images/compare-plan.png"
            pageHasFooter
            showMissingInfoHeader={showMissingInfoHeader}
          />
        ) : (
          <>
            <IdrAlertsContainer>
              <IdrAlerts
                goToReviewYourPlan={isValid ? submitForm : scrollToError}
              />
            </IdrAlertsContainer>
            <HeaderContainer>
              <Left>
                <Header h2>{headerText}</Header>
                <LastUpdatedFsaLoans />
              </Left>
              {renderSyncButton}
            </HeaderContainer>
            <PerkinsAlert formik={formik} />
            {renderLoans}
          </>
        )}
      </>
    );
  };

  return (
    <Container>
      <ManageLoans />

      {loansLoading ? (
        <LoadingBars color={COLORS.azure} />
      ) : (
        <>{renderUserLoans()}</>
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 16px;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;

  @media (max-width: ${MobileSize}) {
    flex-direction: column;
    margin-bottom: 24px;
  }
`;

const Left = styled.div`
  && > * {
    margin-bottom: 16px;
  }

  @media (max-width: ${MobileSize}) {
    margin-top: 16px;
    text-align: center;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: ${MobileSize}) {
    flex-direction: column;
    width: 100%;
  }
`;

const IdrAlertsContainer = styled.div`
  margin-bottom: 24px;
`;

const mapStateToProps = state => ({
  loansLoading:
    isLoading(state, 'fetchLoans') ||
    isLoading(state, 'fetchRepaymentPlanTypes') ||
    isLoading(state, 'fetchLoanTypes'),
  federalLoanTypes: getFederalLoanTypes(state),
  userFederalLoans: getUserFederalLoans(state),
  repaymentPlanTypes: getRepaymentPlanTypes(state),
  canEditField: getCanEditField(state),
  servicersList: getActiveFsaLoanServicerNames(state),
  notActiveServicersList: getInactiveFsaLoanServicerNames(state),
});

export default connect(mapStateToProps, {
  fetchLoans,
  fetchLoanTypes,
  fetchRepaymentPlanTypes,
  fetchSyncStatus,
  openEditALoan,
})(LoansList);
