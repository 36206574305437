import { Formik } from 'formik';
import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import {
  Header,
  Text,
  TextInput,
  Button,
  FlexColumn,
  COLORS,
  Alert,
} from '@summer/ui-components';

import EmailBox from 'components/pages/settings/EmailBox';
import GlobalError from 'components/shared/GlobalError';
import { TabletSize } from 'constants/styleguide';
import { sendNewEmailVerificationEmail } from 'redux/actions/user.actions';
import {
  getUserEmail,
  getUserNewEmail,
  getUserIsVerified,
} from 'redux/selectors/user.selectors';
import { updateEmailValidationSchema } from 'schemas/user';

const initialValues = {
  email: '',
  password: '',
};

const VerifyNewEmail = ({ currentEmail, newEmail }) => {
  const dispatch = useDispatch();
  const [isSubmitted, setSubmitted] = useState(false);

  const reSendVerificationEmail = useCallback(() => {
    dispatch(sendNewEmailVerificationEmail());
    setSubmitted(true);
  }, [dispatch]);

  return (
    <VerifyNewEmailContainer>
      <HeaderContainer>
        <Header h4 color="secondary">
          Update your Email address
        </Header>
      </HeaderContainer>
      <EmailBox email={currentEmail} isVerified={true} />
      <EmailBox email={newEmail} isVerified={false} />

      {isSubmitted && (
        <NewEmailAlert theme="success" dismissable={false}>
          A new verification email has been sent. Please check your inbox.
        </NewEmailAlert>
      )}
      {!isSubmitted && (
        <NewEmailAlert theme="caution" dismissable={false}>
          To begin using the new email address as your primary account address,
          please verify the email. Click the button below to receive a new
          verification email.
        </NewEmailAlert>
      )}

      <Button onClick={reSendVerificationEmail}>
        Send new verification email
      </Button>
    </VerifyNewEmailContainer>
  );
};

const UpdateEmailForm = ({
  formik,
  error,
  isLoading,
  submitted,
  currentEmail,
  newEmail,
  isCurrentEmailVerified,
}) => {
  const {
    values,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    touched,
    isSubmitting,
    isValid,
  } = formik;

  return (
    <Form onSubmit={handleSubmit}>
      <HeaderContainer>
        <Header h4 color="secondary">
          Update your Email address
        </Header>
      </HeaderContainer>

      <EmailBox email={currentEmail} isVerified={isCurrentEmailVerified} />
      <Text bold id={newEmail}>
        {' '}
        New Email{' '}
      </Text>

      <NewEmail>
        <Text light>
          If you change your email address, you&apos;ll have to re-verify your
          account.
        </Text>
      </NewEmail>

      <TextInput
        name="email"
        touched={touched.email}
        isSubmitting={isSubmitting}
        error={errors.email}
        value={values.email}
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={!error && submitted}
        inputMode="email"
        autoComplete="off"
      />
      <TextInput
        label="Password"
        name="password"
        type="password"
        touched={touched.password}
        isSubmitting={isSubmitting}
        error={errors.password}
        value={values.password}
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={!error && submitted}
        autoComplete="current-password"
      />

      <GlobalError label="updateEmail" />
      <Button
        disabled={!isValid || (!error && submitted)}
        isLoading={isLoading}
        type="submit"
      >
        {!error && submitted ? 'Email updated!' : 'Update'}
      </Button>
    </Form>
  );
};

const UpdateEmail = ({ error, isLoading, handleSubmit }) => {
  const dispatch = useDispatch();
  const currentEmail = useSelector(getUserEmail);
  const newEmail = useSelector(getUserNewEmail);
  const isCurrentEmailVerified = useSelector(getUserIsVerified);
  const [submitted, isSubmitted] = useState(false);

  const handleEmailSubmit = values => {
    dispatch(handleSubmit(values));
    isSubmitted(true);
  };

  if (newEmail) {
    return <VerifyNewEmail currentEmail={currentEmail} newEmail={newEmail} />;
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleEmailSubmit}
      validationSchema={updateEmailValidationSchema}
    >
      {formik =>
        UpdateEmailForm({
          formik,
          error,
          isLoading,
          submitted,
          currentEmail,
          newEmail,
          isCurrentEmailVerified,
        })
      }
    </Formik>
  );
};

const HeaderContainer = styled.div`
  border-bottom: 1px solid ${COLORS.grey};
  padding-bottom: 8px;
`;

const VerifyNewEmailContainer = styled.div`
  width: 100%;
`;

const NewEmailAlert = styled(Alert)`
  width: 360px;
  margin-bottom: 16px;
`;

const Form = styled(FlexColumn.withComponent('form'))`
  margin-top: 24px;
  margin-bottom: 24px;
  margin-right: 32px;
  max-width: 355px;

  @media (max-width: ${TabletSize}) {
    margin-right: auto;
    margin-left: auto;
  }
`;

const NewEmail = styled.div`
  margin-top: 8px;
  margin-bottom: 11px;
`;

export default UpdateEmail;
