import { SET_NYC_SSO_POC_RESPONSE } from 'constants/actionTypes';

const initialState = null;

const nycReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_NYC_SSO_POC_RESPONSE: {
      return payload;
    }

    default:
      return state;
  }
};

export default nycReducer;
