import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { values } from 'lodash';

import { COLORS } from '../../helpers/constants/styleguide';

import { FlexColumn } from '../Flex';
import Icon from '../Icon';

import { hexToRgba } from '../../helpers/utils/common';

const iconSizes = {
  DEFAULT: 'default',
  SMALL: 'small',
};

const paintOptions = {
  fill: 'fill',
  stroke: 'stroke',
  both: 'both',
};

const IconMedallion = ({
  SvgComponent,
  className,
  color = COLORS.azure,
  iconColor = null,
  backgroundColor = null,
  size = 40,
  iconSize = 'default',
  paintWith = 'fill',
}) => {
  const scale = iconSize === iconSizes.SMALL ? 0.36 : 0.6;

  const paintProps = {
    fill: paintWith !== 'stroke' ? iconColor ?? color : undefined,
    stroke: paintWith !== 'fill' ? iconColor ?? color : undefined,
  };

  return (
    <Circle
      color={color}
      backgroundColor={backgroundColor}
      size={size}
      className={className}
    >
      <Icon
        SvgComponent={SvgComponent}
        width={size * scale}
        height={size * scale}
        {...paintProps}
      />
    </Circle>
  );
};

const Circle = styled(FlexColumn).attrs({
  className: 'icon-medallion',
})`
  border-radius: 50%;
  background-color: ${props =>
    props.backgroundColor ?? hexToRgba(props.color, 0.2)};
  height: ${props => props.size}px;
  width: ${props => props.size}px;
  min-width: ${props => props.size}px;
  justify-content: center;
  align-items: center;
`;

IconMedallion.propTypes = {
  size: PropTypes.number,
  color: PropTypes.oneOf(values(COLORS)),
  iconSize: PropTypes.oneOf(values(iconSizes)),
  paintWith: PropTypes.oneOf(values(paintOptions)),
};

export default IconMedallion;
