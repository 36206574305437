import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { Strong, Alert } from '@summer/ui-components';

import StaleFsaAlert from 'components/pages/loans/components/alerts/StaleFsaAlert';
import {
  userHasLoansInIdr,
  userNeedsToRecertifyIdr,
  getUserIdrPlanTypeNames,
  getUserRecertificationDate,
  userRecertificationDateHasPassed,
} from 'redux/selectors/loans.selectors';
import { getIsFsaLoanFileStale } from 'redux/selectors/sync.selectors';
import { isLoading } from 'redux/selectors/ui.selectors';
import { getIdrOnboardingQuestion } from 'redux/selectors/user.selectors';
import { timeRemainingUntilDate } from 'utils/date';

class IdrAlerts extends React.Component {
  alertCTA = () => ({
    label: 'Continue',
    buttonWidth: 150,
    type: 'submit',
    isLoading: this.props.isLoading,
    onClick: this.props.goToReviewYourPlan,
  });

  renderUserMissedRecertification = () => (
    <Container>
      <Alert callToAction={this.alertCTA()} theme="warning">
        It looks like you missed your re-certification date which can cause your
        monthly payment to increase. You should{' '}
        <Strong>
          submit a new application immediately to remain in an IDR plan.
        </Strong>
      </Alert>
    </Container>
  );

  renderUserNeedsToRecertify = () => (
    <Container>
      <Alert callToAction={this.alertCTA()} theme="warning">
        You’re now just{' '}
        {timeRemainingUntilDate(this.props.userRecertificationDate)} from your
        recertification date.{' '}
        <Strong>
          Submit a new application immediately to remain in an IDR plan.
        </Strong>
      </Alert>
    </Container>
  );

  renderLooksLikeYoureInIdr = () => (
    <Container>
      <Alert>
        Looks like you’re already enrolled in an income-driven repayment plan.
        Proceed to Review Your Plan to check out different repayment plan
        options.
      </Alert>
    </Container>
  );

  renderLooksLikeYoureNotInIdr = () => (
    <Container>
      <Alert>
        Looks like you’re not in any income-driven repayment plans, only
        Standard. Proceed to Review Your Plan to check out different repayment
        plan options.
      </Alert>
    </Container>
  );

  renderStaleFsaLoanFileAlert = () => (
    <Container>
      <StaleFsaAlert />
    </Container>
  );

  render() {
    const {
      userHasLoansInIdr,
      userNeedsToRecertifyIdr,
      userSaidTheyreInIdr,
      userRecertificationDateHasPassed,
      isFsaLoanFileStale,
    } = this.props;

    if (isFsaLoanFileStale) {
      return this.renderStaleFsaLoanFileAlert();
    }

    if (userNeedsToRecertifyIdr) {
      // If a user needs to recertify, we allow them to move forward with recertification only.
      if (userRecertificationDateHasPassed) {
        return this.renderUserMissedRecertification();
      }
      if (userNeedsToRecertifyIdr) {
        return this.renderUserNeedsToRecertify();
      }
      return null;
    }
    if (
      userHasLoansInIdr === true &&
      (userSaidTheyreInIdr === 'no' || userSaidTheyreInIdr === 'maybe')
    ) {
      return this.renderLooksLikeYoureInIdr();
    }
    if (
      userHasLoansInIdr === false &&
      (userSaidTheyreInIdr === 'yes' || userSaidTheyreInIdr === 'maybe')
    ) {
      return this.renderLooksLikeYoureNotInIdr();
    }

    return <div />;
  }
}

const Container = styled.div`
  margin-bottom: 8px;
`;

const mapStateToProps = state => ({
  isLoading: isLoading(state, 'updateCurrentIdrForm'),
  userHasLoansInIdr: userHasLoansInIdr(state),
  userNeedsToRecertifyIdr: userNeedsToRecertifyIdr(state),
  userSaidTheyreInIdr: getIdrOnboardingQuestion(state),
  userIdrPlanTypeNames: getUserIdrPlanTypeNames(state),
  userRecertificationDate: getUserRecertificationDate(state),
  userRecertificationDateHasPassed: userRecertificationDateHasPassed(state),
  isFsaLoanFileStale: getIsFsaLoanFileStale(state),
});

export default connect(mapStateToProps)(IdrAlerts);
