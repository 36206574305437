import { map } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import ForgivenessProgramItem from 'components/pages/forgivenessFinder/components/ForgivenessProgramItem';
import { MobileSize } from 'constants/styleguide';

const ForgivenessProgramsList = ({
  forgivenessPrograms,
  handleProgramClick,
}) => {
  return (
    <Box>
      {map(forgivenessPrograms, ({ title, award, description, uid }) => {
        return (
          <ForgivenessProgramItem
            key={uid}
            uid={uid}
            award={award}
            description={description}
            title={title}
            onClick={handleProgramClick}
          />
        );
      })}
    </Box>
  );
};

const Box = styled.div`
  & > div {
    margin-bottom: 16px;
  }

  @media (max-width: ${MobileSize}) {
    & > div {
      margin-bottom: 0;
    }
  }
`;

ForgivenessProgramsList.propTypes = {
  forgivenessPrograms: PropTypes.array.isRequired,
  handleProgramClick: PropTypes.func.isRequired,
};

export default ForgivenessProgramsList;
