import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { Header, Text, FlexRow, COLORS } from '@summer/ui-components';

import Platform, { MOBILE } from 'components/hoc/Platform';
import { MobileSize } from 'constants/styleguide';

const ForgivenessProgramItem = ({
  uid,
  title,
  description,
  award,
  platformType,
  onClick,
}) => {
  const isMobile = platformType === MOBILE;
  return (
    <Box onClick={onClick(uid)}>
      <Header h3>{title}</Header>
      <Row>
        {!isMobile && (
          <Description>
            <Text paragraph>{description}</Text>
          </Description>
        )}
        <Award>
          <Text align="right" color="secondary" bold>
            {award}
          </Text>
        </Award>
      </Row>
    </Box>
  );
};

const Box = styled.div`
  min-height: 130px;
  border-radius: 3px;
  box-shadow: 0 2px 10px 0 #e6e7e8;
  border: solid 1px ${COLORS.lighterGrey};
  cursor: pointer;
  padding: 32px;

  :hover {
    background-color: ${COLORS.lightestGrey};
    box-shadow: 0 2px 20px 0 rgb(219, 220, 222);
  }

  @media (max-width: ${MobileSize}) {
    width: 100%;
    box-shadow: none;
    border: none;
    padding: 32px 16px;
    border-bottom: solid 1px ${COLORS.grey};
    :hover {
      background-color: inherit;
      box-shadow: none;
    }
  }
`;

const Description = styled.div`
  width: 50%;
  padding-right: 8px;
`;

const Row = styled(FlexRow)`
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
`;

const Award = styled.div`
  flex: 1 0 auto;
  align-self: flex-end;
`;

ForgivenessProgramItem.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  award: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

ForgivenessProgramItem.displayName = 'ForgivenessProgramItem';

export default Platform(ForgivenessProgramItem);
