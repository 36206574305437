import { flow, set } from 'lodash/fp';

import {
  SET_IS_CURRENTLY_ENROLLED_IN_IDR,
  SET_LOAN_INFO,
  SET_LOGOUT,
  SET_USER,
  SET_USER_EMAIL,
  SET_USER_SURVEY_DATA,
  SET_VERIFICATION_TOKEN,
  VERIFY_USER_EMAIL_SUCCESS,
  SET_HAS_PAYMENT_SAVED,
  SET_IN_SLC,
  SET_ELIGIBILITY_BENEFITS,
} from 'constants/actionTypes';

const initialState = {
  id: null,
  authMechanism: null,
  creditScore: null,
  hasOptedIntoPhoneContact: null,
  email: null,
  familyCount: null,
  federalLoansEstimate: null,
  isCurrentlyEnrolledInIDR: null,
  isPremium: null,
  isPublicSector: null,
  isVerified: null,
  loansInDefaultFederal: null,
  loansInDefaultPrivate: null,
  newEmail: null,
  partnerKey: null,
  privateLoansEstimate: null,
  salary: null,
  troubleWithPayments: null,
  verificationToken: null,
  organization: '',
  hasPaymentSaved: null,
  inSLC: null,
  employeeId: null,
};

const currentUserReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_USER: {
      return flow([
        set('id', payload.user.id),
        set('authMechanism', payload.user.authMechanism),
        set('email', payload.user.email),
        set('federalLoansEstimate', payload.user.federalLoansEstimate),
        set('isCurrentlyEnrolledInIDR', payload.user.isCurrentlyEnrolledInIDR),
        set('isPremium', payload.user.isPremium),
        set('isVerified', payload.user.isVerified),
        set('loansInDefaultFederal', payload.user.loansInDefaultFederal),
        set('loansInDefaultPrivate', payload.user.loansInDefaultPrivate),
        set('newEmail', payload.user.newEmail),
        set('partnerKey', payload.user.partnerKey),
        set('partnerName', payload.user.partnerName),
        set('privateLoansEstimate', payload.user.privateLoansEstimate),
        set('troubleWithPayments', payload.user.troubleWithPayments),
        set('salary', payload.user.salary),
        set('familyCount', payload.user.familyCount),
        set('isPublicSector', payload.user.isPublicSector),
        set('creditScore', payload.user.creditScore),
        set('hasOptedIntoPhoneContact', payload.user.hasOptedIntoPhoneContact),
        set('hasPaymentSaved', payload.user?.hasPaymentSaved),
        set('inSLC', payload.user.inSLC),
      ])(state);
    }

    case SET_USER_EMAIL:
      return flow([set('newEmail', payload.email), set('isVerified', false)])(
        state,
      );

    case SET_LOAN_INFO:
      return flow([
        set('federalLoansEstimate', payload.federalLoansEstimate),
        set('isCurrentlyEnrolledInIDR', payload.isCurrentlyEnrolledInIDR),
        set('loansInDefaultFederal', payload.loansInDefaultFederal),
        set('loansInDefaultPrivate', payload.loansInDefaultPrivate),
        set('privateLoansEstimate', payload.privateLoansEstimate),
      ])(state);

    case SET_USER_SURVEY_DATA:
      return flow([
        set('creditScore', payload.creditScore),
        set('hasOptedIntoPhoneContact', payload.hasOptedIntoPhoneContact),
        set('estIdrMonthlyPayment', payload.estIdrMonthlyPayment),
        set('familyCount', payload.familyCount),
        set('isIdrRecommended', payload.isIdrRecommended),
        set('isPublicSector', payload.isPublicSector),
        set('salary', payload.salary),
        set('troubleWithPayments', payload.troubleWithPayments),
      ])(state);

    case VERIFY_USER_EMAIL_SUCCESS:
      return flow([set('isVerified', true), set('verificationToken', null)])(
        state,
      );

    case SET_LOGOUT:
      return initialState;

    case SET_VERIFICATION_TOKEN:
      return set('verificationToken', payload.token, state);

    case SET_IS_CURRENTLY_ENROLLED_IN_IDR:
      return set(
        'isCurrentlyEnrolledInIDR',
        payload.isCurrentlyEnrolledInIDR,
        state,
      );

    case SET_HAS_PAYMENT_SAVED:
      return set('hasPaymentSaved', payload.hasPaymentSaved, state);

    case SET_IN_SLC:
      return set('inSLC', payload.inSLC, state);

    case SET_ELIGIBILITY_BENEFITS: {
      return set('employeeId', payload.employeeId, state);
    }

    default:
      return state;
  }
};

export default currentUserReducer;
