import * as Sentry from '@sentry/browser';
import HelloSign from 'hellosign-embedded';
import { useEffect, useMemo, useState } from 'react';

import { useKeepSessionAlive } from 'hooks/keepSessionAlive';
import { hideChat, showChat } from 'services/chat';

const HELLOSIGN_APP_CLIENT_ID = import.meta.env
  .REACT_APP_HELLOSIGN_APP_CLIENT_ID;
const ENV = import.meta.env.REACT_APP_NODE_ENV || import.meta.env.NODE_ENV;

export const useHellosign = (
  getSignRequests,
  { onOpen, onClose, onCancel, onSign } = {},
) => {
  /**
   * Needed to prevent multiple requests for sign requests
   */
  const [needsRequest, setNeedsRequest] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const helloSignClient = useMemo(() => {
    const client = new HelloSign({
      clientId: HELLOSIGN_APP_CLIENT_ID,
    });

    client.on('open', () => {
      setIsOpen(true);

      if (onOpen) {
        onOpen();
      }

      hideChat();
    });

    client.on('close', () => {
      setIsOpen(false);

      if (onClose) {
        onClose();
      }

      showChat();

      /**
       * resets the scale when the modal is closed. This is to address a
       * behavior that happens in hellosign where focusing an input zooms the
       * main viewport into the element
       */
      const viewport = document.querySelector('meta[name="viewport"]');
      const { content } = viewport;
      viewport.setAttribute('content', '');
      viewport.setAttribute('content', content);
    });

    client.on('cancel', () => {
      if (onCancel) {
        onCancel();
      }

      setNeedsRequest(true);
    });

    client.on('sign', data => {
      if (onSign) {
        client.close();
        onSign(data);
      }
    });

    client.on('error', e => {
      Sentry.withScope(scope => {
        scope.setTag('Hellosign error', e.code);
        scope.setLevel(Sentry.Severity.Error);

        Sentry.captureMessage(`Error code: ${e.code}, ID: ${e.signatureId}`);
      });
    });

    return client;
  }, [onOpen, onClose, onCancel, onSign]);

  useKeepSessionAlive({
    onTimeout: () => {
      helloSignClient.close();
      setNeedsRequest(true);
    },
    enabled: isOpen,
  });

  useEffect(() => {
    setNeedsRequest(true);
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  useEffect(() => {
    if (needsRequest) {
      getSignRequests();
      setNeedsRequest(false);
    }
  }, [needsRequest, getSignRequests]);

  return [
    embeddedSignUrl => {
      helloSignClient.open(embeddedSignUrl, {
        skipDomainVerification:
          ENV === 'development' || ENV === 'staging' || ENV === 'sandbox',
      });
    },
    helloSignClient,
  ];
};

export const useAlertHellosignError = serverError => {
  const [alertHellosignError, setAlertHellosignError] = useState(false);

  useEffect(() => {
    if (serverError && serverError.status !== 401) {
      setAlertHellosignError(true);
    }
  }, [serverError]);

  return alertHellosignError;
};
