import React from 'react';
import styled from 'styled-components';

import PropTypes from 'prop-types';
import { COLORS, MobileSize } from '../../helpers/constants/styleguide';
import { Header } from '../typography';
import { FlexColumn } from '../Flex';

const Card = ({ title = '', children = <div>Demo div</div> }) => {
  return (
    <Box>
      {title && (
        <Header h4 color={'secondary'} align="center">
          {title}
        </Header>
      )}
      <Content>{children}</Content>
    </Box>
  );
};

const Box = styled(FlexColumn)`
  width: 100%;
  height: 100%;
  border-radius: 3px;
  box-shadow: 0 2px 20px 0 #e6e7e8;
  border: solid 1px ${COLORS.lighterGrey};
  background-image: linear-gradient(to top, #fafafa, #ffffff);
  padding: 32px 24px;
`;

const Content = styled(FlexColumn)`
  height: 100%;

  @media (max-width: ${MobileSize}) {
    flex: 1 0 auto;
  }
`;

Card.propTypes = {
  title: PropTypes.string,
  children: PropTypes.element,
};

export default Card;
