import React from 'react';
import { connect } from 'react-redux';

import OAuthButton from 'components/shared/OAuthButton';

const NycSsoPoc = ({ nycSsoPocResponse }) => (
  <div>
    <div>
      <OAuthButton type="check" provider="nyc" />
    </div>
    {nycSsoPocResponse && (
      <pre>
        <code>{JSON.stringify(nycSsoPocResponse, null, 2)}</code>
      </pre>
    )}
  </div>
);

const mapStateToProps = state => ({
  nycSsoPocResponse: state.nyc,
});

export default connect(mapStateToProps, {})(NycSsoPoc);
