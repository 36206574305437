import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { map } from 'lodash';

import { FlexColumn, FlexRow } from '../Flex';
import { COLORS } from '../../helpers/constants/styleguide';

const VerticalNav = ({ menuItems, activeIndex, width = 202 }) => {
  const itemHeight = 72;
  const activeBarHeight = itemHeight * activeIndex;

  return (
    <Container width={width}>
      {map(menuItems, ({ title, onClick }, idx) => {
        const active = idx <= activeIndex;

        return (
          <NavItem key={title} itemHeight={itemHeight}>
            <TimelineSegment>
              {idx > 0 && <Bar active={active} itemHeight={itemHeight} />}
              <Circle active={active} />
            </TimelineSegment>
            <div>
              <Label active={active} onClick={onClick}>
                {title}
              </Label>
            </div>
          </NavItem>
        );
      })}
      <ActiveBar height={activeBarHeight} />
    </Container>
  );
};

VerticalNav.propTypes = {
  activeIndex: PropTypes.number.isRequired,
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      onClick: PropTypes.func,
    }),
  ).isRequired,
  width: PropTypes.number,
};

const Container = styled(FlexColumn)`
  position: relative;
  width: ${({ width }) => width}px;
  min-width: ${({ width }) => width}px;
`;

const ActiveBar = styled.div`
  background-color: ${COLORS.azure};
  position: absolute;
  width: 4px;
  height: ${props => props.height}px;
  left: 2px;
  top: 10px;

  transition: height 500ms ease-out;
`;

const NavItem = styled(FlexRow)`
  height: ${props => props.itemHeight}px;
  min-height: ${props => props.itemHeight}px;
  position: relative;
`;

const Label = styled.div`
  color: ${({ active }) => (active ? COLORS.azure : COLORS.medGrey)};
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
  font-weight: 600;

  transition: color 500ms ease-out;
  transition-delay: 350ms;
`;

const TimelineSegment = styled.div`
  margin-right: 28px;
`;

const Bar = styled.div`
  background-color: ${COLORS.grey};
  position: absolute;
  width: 4px;
  height: ${props => props.itemHeight}px;
  top: -58px;
  left: 2px;
`;

const Circle = styled.div`
  background-color: ${({ active }) => (active ? COLORS.azure : COLORS.grey)};
  position: absolute;
  width: 8px;
  height: 8px;
  top: 6px;
  border-radius: 50%;

  transition: background-color 500ms ease-out;
  transition-delay: 350ms;
`;

export default VerticalNav;
