import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import lottie from 'lottie-web';

// Lottie assets - Keep in alphabetical order
export { default as CheckmarkLottie } from '../assets/lottie/checkmark.json';
export { default as DotsLottie } from '../assets/lottie/dots.json';
export { default as LoadingBlueLottie } from '../assets/lottie/loadingBlue.json';
export { default as SubmitCelebrationLottie } from '../assets/lottie/submitCelebration.json';

class Lottie extends PureComponent {
  constructor(props) {
    super(props);
    this.animationContainer = React.createRef();
  }

  componentDidMount() {
    const { animationData, eventListeners, loop } = this.props;

    this.options = {
      container: this.animationContainer.current, // the dom element that will contain the animation
      renderer: 'svg',
      loop: loop || false,
      autoplay: false,
      animationData, // the lottie file (json)
    };

    this.animation = lottie.loadAnimation(this.options);
    this.setSpeed();
    window.anim = this.animation;

    this.animation.addEventListener('DOMLoaded', this.setAutoplay);
    this.registerEvents(eventListeners);
  }

  componentWillUnmount() {
    const { eventListeners } = this.props;
    this.animation.removeEventListener('DOMLoaded', this.setAutoplay);
    this.deRegisterEvents(eventListeners);
    this.destroy();
  }

  setAutoplay = () => {
    const { autoplay } = this.props;
    if (autoplay) {
      requestAnimationFrame(() => {
        this.animation.play();
      });
    }
  };

  setSpeed() {
    this.animation.setSpeed(this.props.speed);
  }

  registerEvents = eventListeners => {
    eventListeners.forEach(eventListener => {
      this.animation.addEventListener(
        eventListener.eventName,
        eventListener.callback,
      );
    });
  };

  deRegisterEvents = eventListeners => {
    eventListeners.forEach(eventListener => {
      this.animation.removeEventListener(
        eventListener.eventName,
        eventListener.callback,
      );
    });
  };

  destroy() {
    this.animation.destroy();
  }

  render() {
    const { PlaceHolder } = this.props;
    return (
      <LottieContainer>
        {PlaceHolder && <LottiePlaceHolder>{PlaceHolder}</LottiePlaceHolder>}
        <AnimationContainer ref={this.animationContainer} />
      </LottieContainer>
    );
  }
}

const LottiePlaceHolder = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
`;

const LottieContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

const AnimationContainer = styled.div`
  width: 100%;
  height: 100%;
`;

Lottie.propTypes = {
  eventListeners: PropTypes.arrayOf(PropTypes.func),
  speed: PropTypes.number,
  loop: PropTypes.bool,
  autoplay: PropTypes.bool,
  path: PropTypes.string,
  animationData: PropTypes.object,
  PlaceHolder: PropTypes.element,
};

Lottie.defaultProps = {
  eventListeners: [],
  speed: 1,
  loop: true,
  autoplay: true,
};

export default Lottie;
