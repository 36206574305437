import React from 'react';

import Switch from 'react-switch';
import PropTypes from 'prop-types';
import { COLORS } from '../../helpers/constants/styleguide';

const Toggle = ({ onChange, checked = false, disabled = false }) => {
  return (
    <Switch
      onChange={onChange}
      uncheckedIcon={false}
      checkedIcon={false}
      checked={checked}
      disabled={disabled}
      height={12}
      onHandleColor={COLORS.azure}
      onColor={COLORS.grey}
      offColor={COLORS.grey}
      offHandleColor={COLORS.white}
      handleDiameter={25}
      boxShadow="0 1px 1px 0 rgba(0, 0, 0, 0.24), 0 0 1px 0 rgba(0, 0, 0, 0.12)"
      width={30}
    />
  );
};

Toggle.propTypes = {
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

export default Toggle;
