import { capitalize } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import {
  FlexColumn,
  Text,
  GearLarge,
  Header,
  COLORS,
} from '@summer/ui-components';

import NycSsoPoc from 'components/pages/settings/NycSsoPoc';
import UpdateEmail from 'components/pages/settings/UpdateEmail';
import UpdateName from 'components/pages/settings/UpdateName';
import UpdatePassword from 'components/pages/settings/UpdatePassword';
import OAuthButton from 'components/shared/OAuthButton';
import ContentHeader from 'components/shared/dashboard/ContentHeader';
import Page from 'components/shared/dashboard/Page';
import { Link } from 'components/shared/typography';
import { supportAddress } from 'constants/emailLinks';
import { TabletSize } from 'constants/styleguide';
import { clearServerError } from 'redux/actions/ui.actions';
import { updateEmail, updatePassword } from 'redux/actions/user.actions';
import { getIsNycSsoPocEnabled } from 'redux/selectors/flags.selectors';
import { getErrorMessage, isLoading } from 'redux/selectors/ui.selectors';
import {
  getHideSupportChat,
  getUserAuthMechanism,
  getUserEmail,
} from 'redux/selectors/user.selectors';
import { openChat } from 'services/chat';

// non-standard names that can't just be capitalized
const niceNames = {
  creditkarma: 'CreditKarma',
  cityOfAlexandria: 'City of Alexandria',
  adp: 'ADP',
};

const getNiceSSOName = authMechanism => {
  if (niceNames[authMechanism]) {
    return niceNames[authMechanism];
  }

  return capitalize(authMechanism);
};

class UserSettings extends React.Component {
  constructor() {
    super();

    this.formInitialValues = {
      email: '',
    };
  }

  submitEmailUpdate = ({ email, password }) => {
    this.props.updateEmail({
      email,
      password,
    });
  };

  submitPasswordUpdate = ({ oldPassword, newPassword }) => {
    this.props.updatePassword({
      oldPassword,
      newPassword,
    });
  };

  render() {
    const {
      currentEmail,
      isLoadingEmail,
      isLoadingPassword,
      isNycSsoPocEnabled,
      updateEmailError,
      updatePasswordError,
      authMechanism,
      hideSupportChat,
    } = this.props;

    const isPassword = authMechanism === 'password';
    const isSSOWithManagement = [
      'facebook',
      'google',
      'cityOfAlexandria',
    ].includes(authMechanism);

    return (
      <>
        <Page>
          <ContentHeader SvgComponent={GearLarge} iconClassName="gear-icon">
            Settings
          </ContentHeader>
          <Container>
            <LeftContainer>
              <UpdateName />
              {isPassword && (
                <UpdateEmail
                  handleSubmit={this.submitEmailUpdate}
                  isLoading={isLoadingEmail}
                  error={updateEmailError}
                  currentEmail={currentEmail}
                />
              )}
            </LeftContainer>

            <div>
              {isPassword && (
                <UpdatePassword
                  handleSubmit={this.submitPasswordUpdate}
                  isLoading={isLoadingPassword}
                  error={updatePasswordError}
                />
              )}
              {!isPassword && (
                <OAuthContainer>
                  <HeaderContainer>
                    <Header h4 color="secondary">
                      Manage your account{' '}
                    </Header>
                  </HeaderContainer>
                  <Text paragraph>
                    Looks like you created your account using{' '}
                    {getNiceSSOName(authMechanism)}.
                  </Text>
                  <Bottom>
                    {isSSOWithManagement && (
                      <OAuthButton type="manage" provider={authMechanism} />
                    )}
                    <Text paragraph center>
                      Having issues?{' '}
                      {!hideSupportChat && (
                        <>
                          <Link onClick={openChat}>Chat now</Link> or email
                        </>
                      )}
                      {hideSupportChat && 'Email'} us at{' '}
                      <Link href={`mailto:${supportAddress}`}>
                        {supportAddress}
                      </Link>
                    </Text>
                  </Bottom>
                </OAuthContainer>
              )}
              {isNycSsoPocEnabled && <NycSsoPoc />}
            </div>
          </Container>
        </Page>
      </>
    );
  }
}

const Container = styled.div`
  display: flex;
  justify-content: space-around;
  max-width: 780px;
  width: 100%;
  margin-top: 16px;

  @media (max-width: ${TabletSize}) {
    flex-direction: column;
    align-items: center;
  }
`;

const LeftContainer = styled.div`
  @media (max-width: ${TabletSize}) {
    padding-left: 8px;
    padding-right: 8px;
    width: 100%;
  }
`;

const OAuthContainer = styled.div`
  max-width: 355px;

  @media (max-width: ${TabletSize}) {
    padding-left: 8px;
    padding-right: 8px;
    width: 100%;
  }
`;

const Bottom = styled(FlexColumn)`
  justify-content: space-between;
  margin: 0 auto;
  max-width: 292px;

  & > * {
    margin-top: 24px;
    display: block;
  }
`;

const HeaderContainer = styled.div`
  margin-top: 24px;
  border-bottom: 1px solid ${COLORS.grey};
  margin-bottom: 16px;
  padding-bottom: 8px;
`;

const mapStateToProps = state => ({
  isLoadingEmail: isLoading(state, 'updateEmail'),
  isLoadingPassword: isLoading(state, 'updatePassword'),
  isNycSsoPocEnabled: getIsNycSsoPocEnabled(state),
  updateEmailError: getErrorMessage(state, 'updateEmail'),
  updatePasswordError: getErrorMessage(state, 'updatePassword'),
  currentEmail: getUserEmail(state),
  authMechanism: getUserAuthMechanism(state),
  hideSupportChat: getHideSupportChat(state),
});

export default connect(mapStateToProps, {
  updateEmail,
  updatePassword,
  clearServerError,
})(UserSettings);
