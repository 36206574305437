import React from 'react';
import PropTypes from 'prop-types';
import OtpInput from 'react-otp-input';
import { COLORS } from '../../../helpers/constants/styleguide';
import styled from 'styled-components';

const OTPInput = ({
  value,
  numberOfInputs = 6,
  onChange,
  separator,
  placeholder,
  shouldAutoFocus,
  width = 55,
  height = 55,
  inputStyle,
}) => {
  return (
    <OtpInput
      value={value}
      onChange={onChange}
      numInputs={numberOfInputs}
      renderSeparator={separator}
      containerStyle={{ gap: 10 }}
      inputStyle={{ width, height, ...inputStyle }}
      shouldAutoFocus={shouldAutoFocus}
      placeholder={placeholder}
      renderInput={props => <Input {...props} />}
    />
  );
};

const Input = styled.input`
  border: 1px solid ${COLORS.medGrey};
  border-radius: 5px;
  &:focus {
    outline: none;
    box-shadow: 0px 0px 2px ${COLORS.azure};
  }
`;

OTPInput.propTypes = {
  value: PropTypes.string,
  numberOfInputs: PropTypes.number,
  onChange: PropTypes.func,
  separator: PropTypes.node,
  placeholder: PropTypes.string,
  shouldAutoFocus: PropTypes.bool,
};

export default OTPInput;
