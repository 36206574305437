import React from 'react';
import PropTypes from 'prop-types';
import { values } from 'lodash';
import styled from 'styled-components';

import Lottie, { LoadingBlueLottie } from '../Lottie';
import { COLORS } from '../../helpers/constants/styleguide';

const LoadingBars = ({ color = COLORS.white }) => {
  return (
    <Container color={color} className="loading-bars">
      <Lottie
        autoplay={true}
        loop={true}
        speed={1}
        animationData={LoadingBlueLottie}
      />
    </Container>
  );
};

LoadingBars.propTypes = {
  color: PropTypes.oneOf(values(COLORS)),
};

const Container = styled.div`
  margin: 0 auto;
  width: 56px;
  height: 16px;

  & path {
    fill: ${props => props.color || COLORS.white};
  }
`;

export default LoadingBars;
