import { get, isNil } from 'lodash';

const LOCAL_STORAGE_KEY = 'flags';

export const hasLocalStorage = () => {
  if (typeof window !== 'object' || !('localStorage' in window)) {
    return false;
  }

  try {
    const storage = window.localStorage;
    const testKey = `featureFlags localStorage test`;
    storage.setItem(testKey, 'test');
    storage.getItem(testKey);
    storage.removeItem(testKey);
  } catch (e) {
    if (import.meta.env.NODE_ENV !== 'production') {
      // eslint-disable-next-line no-console
      console.warn(
        `featureFlags localStorage test failed, featureFlags will be disabled.`,
      );
    }
    return false;
  }
  return true;
};

const getFlags = () => {
  if (hasLocalStorage()) {
    const flagsString = localStorage.getItem(LOCAL_STORAGE_KEY);

    try {
      return JSON.parse(flagsString);
    } catch (e) {
      return null;
    }
  }
  return null;
};

const saveFlag = ({ flagName, flagValue }) => {
  const flags = getFlags() || {};

  flags[flagName] = flagValue;
  if (hasLocalStorage()) {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(flags));
  }
};

const clearFlags = () => {
  if (hasLocalStorage()) {
    localStorage.removeItem(LOCAL_STORAGE_KEY);
  }
};

export const getFlag = flagName => {
  const flags = getFlags() || {};
  return get(flags, flagName, false);
};

export const initFeatureFlags = (location, email, flags) => {
  if (isNil(location)) {
    return;
  }

  const query = new URLSearchParams(location.search);

  if (query.get('clearFlags')) {
    clearFlags();
    return;
  }

  query.forEach((queryParamValue, queryParam) => {
    if (Object.prototype.hasOwnProperty.call(flags, queryParam)) {
      saveFlag({ flagName: queryParam, flagValue: queryParamValue });
    }
  });
};

export const initFeatureFlagsFromObject = (flags = {}) => {
  if (hasLocalStorage()) {
    const storage = window.localStorage;
    const currentFlags = JSON.parse(storage.getItem('flags'));
    storage.setItem(
      'flags',
      JSON.stringify({
        ...currentFlags,
        ...flags,
      }),
    );
  }
};
