import {
  CLEAR_SERVER_ERROR,
  CLOSE_MANAGE_LOANS,
  FINISH_LOADING,
  LOG_TOOLTIP,
  NEXT_LOAN,
  OPEN_EDIT_A_LOAN,
  OPEN_CONNECT_FSA_MODAL,
  CLOSE_CONNECT_FSA_MODAL,
  PREV_LOAN,
  SET_SIDEBAR_VISIBILITY,
  SHOW_SERVER_ERROR,
  START_LOADING,
  SET_S3_UPLOAD_PROGRESS,
  TOGGLE_IDR_QUESTIONNAIRE,
  SET_QUESTIONNAIRE_INITIAL_STEP,
  SET_TUITION_REIMBURSEMENT_UI,
} from 'constants/actionTypes';

export const finishLoading = ({ label }) => ({
  type: FINISH_LOADING,
  payload: {
    label,
  },
});

export const startLoading = ({ label }) => ({
  type: START_LOADING,
  payload: {
    label,
  },
});

export const showServerError = ({ label, error }) => ({
  type: SHOW_SERVER_ERROR,
  payload: {
    label,
    error,
  },
});

export const clearServerError = ({ label } = {}) => ({
  type: CLEAR_SERVER_ERROR,
  payload: {
    label,
  },
});

export const logTooltip = (kind = null) => ({
  type: LOG_TOOLTIP,
  payload: {
    kind,
  },
});

export const setSidebarVisibility = visible => ({
  type: SET_SIDEBAR_VISIBILITY,
  payload: {
    visible,
  },
});

export const openEditALoan = (loanIdx, programType) => ({
  type: OPEN_EDIT_A_LOAN,
  payload: {
    loanIdx,
    programType,
  },
});

export const openConnectFsa = payload => ({
  type: OPEN_CONNECT_FSA_MODAL,
  payload,
});

export const closeConnectFsa = payload => ({
  type: CLOSE_CONNECT_FSA_MODAL,
  payload,
});

export const closeManageLoans = () => ({ type: CLOSE_MANAGE_LOANS });

export const nextLoan = () => ({ type: NEXT_LOAN });

export const prevLoan = () => {
  return { type: PREV_LOAN };
};

export const setS3UploadProgress = payload => ({
  type: SET_S3_UPLOAD_PROGRESS,
  payload,
});

export const toggleIdrQuestionnaire = payload => ({
  type: TOGGLE_IDR_QUESTIONNAIRE,
  payload,
});

export const setQuestionnaireInitialStep = payload => ({
  type: SET_QUESTIONNAIRE_INITIAL_STEP,
  payload,
});

export const setTuitionReimbursementUi = payload => ({
  type: SET_TUITION_REIMBURSEMENT_UI,
  payload,
});
