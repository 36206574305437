import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Header from '../typography/Header';
import Text from '../typography/Text';

const DataSection = ({ title, data, colWidth }) => (
  <Section key={title}>
    <SectionTitle size="h5" color="secondary">
      {title}
    </SectionTitle>
    {data.map((row, i) => {
      return (
        <GridRow key={i} colWidth={colWidth} numColumns={row.length}>
          {row.map(item => {
            return (
              <div key={item.label}>
                <Label color="secondary">{item.label}</Label>
                <Text weight="bold" size="large">
                  {item.value}
                </Text>
              </div>
            );
          })}
        </GridRow>
      );
    })}
  </Section>
);

const DataDisplay = ({ data }) =>
  data.map(section => (
    <DataSection
      key={section.title}
      title={section.title}
      data={section.data}
      colWidth={section.colWidth || '1fr'}
    />
  ));

const Section = styled.div`
  margin-bottom: 40px;
`;

const SectionTitle = styled(Header)`
  margin-bottom: 15px;
`;

const GridRow = styled.div`
  display: grid;
  grid-gap: 50px;
  grid-template-columns: ${({ colWidth, numColumns }) =>
    `${colWidth} `.repeat(numColumns)};

  margin-bottom: 24px;
`;

const Label = styled(Text)`
  margin-bottom: 8px;
`;

DataDisplay.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      data: PropTypes.arrayOf(
        PropTypes.arrayOf(
          PropTypes.shape({
            label: PropTypes.string.isRequired,
            value: PropTypes.string.isRequired,
          }).isRequired,
        ),
      ).isRequired,
      colWidth: PropTypes.string,
    }).isRequired,
  ),
};

export default DataDisplay;
