import React from 'react';
import ReactAnimatedNumber from 'react-animated-number';
import PropTypes from 'prop-types';

const AnimatedNumber = ({
  fontSize = 34,
  value = 0,
  formatValue = n => n,
  stepPrecision = 0,
}) => {
  return (
    <ReactAnimatedNumber
      style={{
        fontSize: fontSize,
        transition: '0.5s ease-out',
        transitionProperty: 'background-color, color, opacity',
      }}
      stepPrecision={stepPrecision}
      duration={850}
      value={value}
      formatValue={formatValue}
    />
  );
};

AnimatedNumber.propTypes = {
  value: PropTypes.number.isRequired,
  fontSize: PropTypes.number,
  formatValue: PropTypes.func,
  stepPrecision: PropTypes.number,
};

export default AnimatedNumber;
