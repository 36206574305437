import React from 'react';
import styled from 'styled-components';

import { Field } from 'formik';
import { COLORS } from '../../../helpers/constants/styleguide';

class RadioButton extends React.Component {
  constructor() {
    super();
    this.radioInput = React.createRef();
    this.RadioButtonContainer = React.createRef();
  }

  handleClick = () => {
    const radioInputElement = this.radioInput.current;
    radioInputElement.click();
  };

  render() {
    const {
      label,
      name,
      value,
      onChange,
      validate,
      checked,
      backgroundColor = COLORS.azure,
      textColor = COLORS.blueBlack,
    } = this.props;

    return (
      <>
        <RadioButtonInput
          style={{ position: 'absolute', transform: 'scale(0)' }}
          type="radio"
          onChange={onChange}
          checked={checked}
          name={name}
          value={value}
          validate={validate}
          innerRef={this.radioInput}
          data-testid={`radio-button-${name}-${value}`}
        />
        <StyledRadioButton
          ref={this.RadioButtonContainer}
          checked={checked}
          onClick={this.handleClick}
          backgroundColor={backgroundColor}
          textColor={textColor}
        >
          <Label>{label}</Label>
        </StyledRadioButton>
      </>
    );
  }
}

const RadioButtonInput = styled(Field)`
  &:focus-visible + div {
    box-shadow: 0 0 0 2.5px ${COLORS.white}, 0px 0px 0px 4px ${COLORS.azure};
  }
`;

const StyledRadioButton = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  min-height: 48px;
  border-radius: 3px;
  //leah ask that this time padding will be 13
  padding: 13px 32px;
  mix-blend-mode: multiply;
  transform: translate3d(0, 0, 0);

  cursor: pointer;
  ${({ checked, backgroundColor, textColor }) => {
    return `
      background-color: ${
        checked ? `${backgroundColor} !important` : COLORS.lighterGrey
      };
      color: ${checked ? COLORS.white : textColor};
      `;
  }};

  &:focus-visible,
  &:hover {
    background-color: ${COLORS.grey};
    outline: none;
  }
`;

const Label = styled.div`
  align-self: center;
  text-align: center !important;
  font-size: 16px;
  font-weight: 600;
`;

export default RadioButton;
