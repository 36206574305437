import { startCase, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { Text, COLORS } from '@summer/ui-components';

import Link from 'components/shared/typography/Link';
import { showServerError } from 'redux/actions/ui.actions';

const manageLoginCredentialUrls = {
  google: 'https://myaccount.google.com/security',
  facebook: 'https://www.facebook.com/settings?tab=security',
  cityOfAlexandria: 'https://www.alexandriava.gov/',
};

const providerImages = {
  google: '/images/google-logo.png',
  facebook: '/images/facebook-logo.png',
  cityOfAlexandria: '/images/partners/city-of-alexandria-logo.png',
  nyc: '/images/partners/nyc.png',
};

const OAuthButton = ({
  provider,
  type,
  partner,
  partnerCode,
  usePartnerCode,
  usePartnerGuid,
  partnerGuid,
  identifierValue,
}) => {
  const dispatch = useDispatch();

  const getLink = () => {
    const host = import.meta.env.REACT_APP_API_BASE_URL;
    const path = provider;

    // redirectTo shouldn't be separately encoded and listed as a param in the object
    // below because it could already contain parameters
    const params = {
      clientRedirect: 'true',
      partner,
      partnerCode,
      usePartnerCode,
      usePartnerGuid,
      partnerGuid,
      identifierValue,
      referrer: document.referrer,
    };

    const searchParamsArr = [];

    new URLSearchParams(window.location.search).forEach(
      function addSearchParams(value, key) {
        searchParamsArr.push(`${key}=${value}`);
      },
    );

    const query = Object.keys(params)
      .filter(key => !!params[key])
      .map(key => `${key}=${encodeURIComponent(params[key])}`)
      .concat(searchParamsArr)
      .join('&');

    return `${host}/${path}?redirectTo=${window.location.origin}${
      window.location.pathname
    }${encodeURIComponent(window.location.search)}&${query}`;
  };

  const handleClick = () => {
    // TODO: [ch19193] Refactor showServerError action to handle client errors too
    if (usePartnerCode && isEmpty(partnerCode)) {
      dispatch(
        showServerError({
          label: 'oauth',
          error: {
            message:
              'Please enter the access code provided by your organization below.',
            status: 200,
          },
        }),
      );
    } else {
      window.location.href = getLink();
    }
  };

  const renderContents = (src, text) => {
    return (
      <>
        <SocialIcon src={src} />
        <Text large bold>
          {text}
        </Text>
      </>
    );
  };

  const formatted = startCase(provider);
  const src = providerImages[provider];

  let text;
  let onClick = handleClick;

  switch (type) {
    case 'create':
      text = `Sign up with ${formatted}`;
      break;
    case 'login':
      text = `Sign in with ${formatted}`;
      break;
    case 'check':
      text = 'Test connection';
      break;
    case 'manage':
    default:
      text = 'Manage login credentials';
      onClick = null;
  }

  if (['create', 'login', 'check'].includes(type)) {
    return (
      <SocialButton onClick={onClick}>{renderContents(src, text)}</SocialButton>
    );
  }
  return (
    <Link href={manageLoginCredentialUrls[provider]}>
      <SocialButton>{renderContents(src, text)}</SocialButton>
    </Link>
  );
};

const SocialIcon = styled.img`
  padding-right: 16px;
  height: 24px;
  width: auto;
`;

const SocialButton = styled.div`
  background-color: ${COLORS.white};
  border-radius: 3px;
  padding: 16px 16px 18px;
  width: 100%;
  display: flex;
  align-items: center;

  box-shadow: 0 1px 4px 0 rgba(124, 139, 147, 0.3);

  cursor: pointer;

  &:hover {
    background-color: ${COLORS.lightestGrey};
  }
`;

OAuthButton.propTypes = {
  /** The oauth provider */
  provider: PropTypes.string,
  /** The src of the image for the icon */
  src: PropTypes.string,
};

export default OAuthButton;
