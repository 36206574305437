import React from 'react';
import RCSlider from 'rc-slider';
import 'rc-slider/dist/rc-slider.css';
import PropTypes from 'prop-types';

import { COLORS } from '../../../helpers/constants/styleguide';

const Slider = ({ min = 0, max, defaultValue = 0, value, handleChange }) => (
  <RCSlider
    min={min}
    max={max}
    defaultValue={defaultValue}
    value={value}
    onChange={handleChange}
    handleStyle={{
      height: 24,
      width: 24,
      marginTop: -8,
      borderColor: COLORS.azure,
      backgroundColor: COLORS.azure,
    }}
    trackStyle={{ height: 8, backgroundColor: COLORS.azure }}
    railStyle={{ height: 8, backgroundColor: COLORS.blueGrey }}
  />
);

Slider.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  defaultValue: PropTypes.number,
  onChange: PropTypes.func,
};

export default Slider;
