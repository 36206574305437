import React from 'react';
import styled, { css } from 'styled-components';
import { Field } from 'formik';

import { COLORS } from '../../../helpers/constants/styleguide';
import { FlexColumn } from '../../Flex';

import { Text } from '../../typography';

class Input extends React.Component {
  state = {
    focus: false,
  };

  handleFocusChange = (focused = null) => {
    this.setState(state => ({
      focus: focused ?? !state.focus,
    }));
  };

  render() {
    const { focus } = this.state;
    const {
      children,
      error,
      touched,
      width,
      height,
      isSubmitting,
      label,
      noPadding,
      noClipping,
      subTitle,
      helpMessage,
      theme = 'default',
      id,
    } = this.props;

    return (
      <Wrapper id={id}>
        {label && (
          <Label htmlFor={`${id}-input`} focus={focus}>
            <Text bold>{label}</Text>
          </Label>
        )}
        {subTitle && <SubTitle paragraph>{subTitle}</SubTitle>}
        <InputContainer
          width={width}
          height={height}
          error={error}
          focus={focus}
          isSubmitting={isSubmitting}
          touched={touched}
          helpMessage={helpMessage}
          noClipping={noClipping}
          $theme={theme}
        >
          <Container noPadding={noPadding}>
            {children(this.handleFocusChange, focus)}
          </Container>
        </InputContainer>
        <ErrorMessage theme={theme}>
          {(touched || isSubmitting) && !!error ? (
            <Text size="small" color="error" data-testid="input-error">
              {error}
            </Text>
          ) : (
            (
              <Text size="small" color="secondary">
                {helpMessage}
              </Text>
            ) || ''
          )}
        </ErrorMessage>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  width: 100%;
`;

const Container = styled(FlexColumn)`
  flex: 1;
`;

const bottomBorder = css`
  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: inherit;
    height: 2px;
    transition: all 0.4s ease;
    ${({ focus }) => focus && `background-color:${COLORS.azure}`};
    ${({ error, touched, isSubmitting, focus }) =>
      (isSubmitting || touched) &&
      error &&
      focus &&
      `background-color:${COLORS.orange}`};
  }
`;

const overrideInputContainer = css`
  ${({ $theme, error }) =>
    $theme === 'noBorder' &&
    ` border: none;
      &:before {
        background-color: ${error ? COLORS.orange : COLORS.azure};
      }`}
  ${({ noClipping }) => noClipping && `overflow: visible;`}
`;

const InputContainer = styled(FlexColumn)`
  position: relative;
  flex: 1;
  border-radius: 3px;
  background-color: ${COLORS.white};
  border: 1px solid ${COLORS.grey};
  overflow: hidden;

  ${({ width, height }) => `
  height: ${height ?? '48px'};
  width: ${width ?? '100%'};`}

  ${bottomBorder};
  ${overrideInputContainer};
`;

const Label = styled.label`
  display: block;
  margin-bottom: 8px;
`;

const SubTitle = styled(Text)``;

const overrideErrorMessage = css`
  ${({ theme }) =>
    theme === 'noBorder' &&
    `
    min-height: 0;`}
`;

const ErrorMessage = styled.div`
  margin-top: 4px;
  min-height: calc(14px * 1.5);
  ${overrideErrorMessage}
`;

const overrideFormInput = css`
  ${({ $theme, error }) =>
    $theme === 'noBorder' &&
    `
    font-size: 18px;
    font-weight: 600;
    text-align: right;
    padding-right: 0;
    color: ${error ? COLORS.orange : COLORS.azure};
    `}
`;

export const FormInput = styled(Field)`
  font-size: 16px;
  font-weight: 300;
  color: ${COLORS.darkerGrey};
  padding: 0 16px;

  ::placeholder {
    color: ${COLORS.medGrey};
    opacity: 1; /* Firefox */
  }
  ${overrideFormInput}
`;

export const FormInputStyle = styled.div`
  font-size: 20px;
  font-weight: 300;
  color: ${COLORS.darkerGrey};
  ${overrideFormInput}
`;

export default Input;
