import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import {
  Header,
  FlexRow,
  Collapse,
  Icon,
  ArrowUp,
  COLORS,
} from '@summer/ui-components';

const PayOff = ({ openByDefault = false, children, hide }) =>
  !hide && (
    <Collapse
      openByDefault={openByDefault}
      trigger={({ openState }) => (
        <CollapseTrigger>
          <Header h4>Paid Off Loans</Header>
          <Arrow
            fill={COLORS.blueBlack}
            open={openState}
            SvgComponent={ArrowUp}
          />
        </CollapseTrigger>
      )}
    >
      {children}
    </Collapse>
  );

PayOff.propTypes = {
  openByDefault: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

const CollapseTrigger = styled(FlexRow)`
  width: 100%;
  cursor: pointer;
  padding: 16px 0;
  margin-bottom: 24px;
  border-bottom: 1px solid ${COLORS.grey};
`;

const Arrow = styled(Icon)`
  cursor: pointer;
  margin-left: 8px;
  transform: ${props => (props.open ? 'rotate(0deg)' : 'rotate(180deg)')};
  transition: transform 0.3s ease-out;
`;

export default PayOff;
