import { Formik } from 'formik';
import { isNil } from 'lodash';
import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import {
  FlexRow,
  TextInput,
  COLORS,
  Text,
  Button,
} from '@summer/ui-components';

import GlobalError from 'components/shared/GlobalError';
import { TabletSize } from 'constants/styleguide';
import { createPartialDataAccount } from 'redux/actions/user.actions';
import { getErrorMessage, isLoading } from 'redux/selectors/ui.selectors';
import { partialDataCreateAccountValidationSchema } from 'schemas/onboard';
import { scrollToError } from 'utils/formik';

const PartialDataCreateAccountForm = ({ globalErrorMessage, isLoading }) => {
  const queryParams = new URLSearchParams(window.location.search);
  const isSecureEligible = queryParams.get('isSecureEligible') || '';
  const firstName = decodeURIComponent(queryParams.get('firstName') || '');
  const lastName = decodeURIComponent(queryParams.get('lastName') || '');
  const email = queryParams.get('email') || '';

  const areAllDataFieldsPresent = firstName && lastName && email;

  const initialValues = isSecureEligible
    ? {
        email,
        firstName,
        lastName,
      }
    : { email: '', firstName: '', lastName: '' };

  const dispatch = useDispatch();

  const handleSubmit = values => {
    const { firstName, lastName, email } = values;
    dispatch(createPartialDataAccount({ firstName, lastName, email }));
  };

  const renderForm = formik => {
    const {
      errors,
      touched,
      values,
      isSubmitting,
      handleChange,
      handleBlur,
      isValid,
      handleSubmit,
    } = formik;

    return (
      <Form onSubmit={handleSubmit}>
        {renderNameInputs({
          errors,
          touched,
          values,
          isSubmitting,
          handleChange,
          handleBlur,
        })}

        <InputContainer>
          <TextInput
            label="Email Address"
            name="email"
            error={errors.email}
            touched={touched.email}
            isSubmitting={isSubmitting}
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            inputMode="email"
            autoComplete="email"
            placeholder="Enter your email address"
          />
        </InputContainer>
        {!isNil(globalErrorMessage) && (
          <ErrorBox>
            <GlobalError label="createPartialDataAccount" />
          </ErrorBox>
        )}
        <Button
          fakeDisabled={!isValid}
          isLoading={isLoading}
          fullWidth={true}
          type="submit"
          onClick={() => scrollToError(errors)}
        >
          {isSecureEligible && areAllDataFieldsPresent
            ? 'Verify my email'
            : 'Continue'}
        </Button>
      </Form>
    );
  };

  const renderNameInputs = ({
    errors,
    touched,
    values,
    isSubmitting,
    handleChange,
    handleBlur,
  }) => {
    if (isSecureEligible) {
      if (firstName && lastName) {
        return (
          <PopulatedNameContainer>
            <PopulatedTitleAndValue>
              <Text bold>First Name</Text>
              <Text>{firstName}</Text>
            </PopulatedTitleAndValue>
            <PopulatedTitleAndValue>
              <Text bold>Last Name</Text>
              <Text>{lastName}</Text>
            </PopulatedTitleAndValue>
          </PopulatedNameContainer>
        );
      }

      if (firstName) {
        return (
          <>
            <PopulatedFieldContainer>
              <PopulatedTitleAndValue>
                <Text bold>First Name</Text>
                <Text>{firstName}</Text>
              </PopulatedTitleAndValue>
            </PopulatedFieldContainer>
            <InputContainer>
              <TextInput
                label="Last Name"
                name="lastName"
                error={errors.lastName}
                touched={touched.lastName}
                isSubmitting={isSubmitting}
                value={values.lastName}
                onChange={handleChange}
                onBlur={handleBlur}
                autoComplete="family-name"
                placeholder="Enter your last name"
              />
            </InputContainer>
          </>
        );
      }

      if (lastName) {
        return (
          <>
            <PopulatedFieldContainer>
              <PopulatedTitleAndValue>
                <Text bold>Last Name</Text>
                <Text>{lastName}</Text>
              </PopulatedTitleAndValue>
            </PopulatedFieldContainer>
            <InputContainer>
              <TextInput
                label="First Name"
                name="firstName"
                error={errors.firstName}
                touched={touched.firstName}
                isSubmitting={isSubmitting}
                value={values.firstName}
                onChange={handleChange}
                onBlur={handleBlur}
                autoComplete="given-name"
                placeholder="Enter your first name"
              />
            </InputContainer>
          </>
        );
      }
    }

    return (
      <>
        <InputContainer>
          <TextInput
            label="First Name"
            name="firstName"
            error={errors.firstName}
            touched={touched.firstName}
            isSubmitting={isSubmitting}
            value={values.firstName}
            onChange={handleChange}
            onBlur={handleBlur}
            autoComplete="given-name"
            placeholder="Enter your first name"
          />
        </InputContainer>
        <InputContainer>
          <TextInput
            label="Last Name"
            name="lastName"
            error={errors.lastName}
            touched={touched.lastName}
            isSubmitting={isSubmitting}
            value={values.lastName}
            onChange={handleChange}
            onBlur={handleBlur}
            autoComplete="family-name"
            placeholder="Enter your last name"
          />
        </InputContainer>
      </>
    );
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={partialDataCreateAccountValidationSchema()}
      onSubmit={handleSubmit}
    >
      {formik => renderForm(formik)}
    </Formik>
  );
};

const InputContainer = styled.div`
  max-width: 436px;
  width: 100%;
  margin-bottom: 8px;

  @media (max-width: ${TabletSize}) {
    max-width: unset;
    width: 100%;
  }
`;

const ErrorBox = styled.div`
  min-height: 50px;
  width: 100%;
`;

const PopulatedNameContainer = styled.div`
  max-width: 436px;
  width: 100%;
  margin-bottom: 32px;
  background-color: ${COLORS.lighterGrey};
  padding: 24px;
  display: flex;
  flex-direction: row;
  border-radius: 4px;

  @media (max-width: ${TabletSize}) {
    max-width: unset;
    width: 100%;
  }
`;

const PopulatedFieldContainer = styled.div`
  max-width: 436px;
  width: 100%;
  margin-bottom: 32px;
  background-color: ${COLORS.lighterGrey};
  padding: 24px;
  border-radius: 4px;

  @media (max-width: ${TabletSize}) {
    max-width: unset;
    width: 100%;
  }
`;

const PopulatedTitleAndValue = styled.div`
  height: 45px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-basis: 100%;
`;

const Form = styled(FlexRow.withComponent('form'))`
  flex-wrap: wrap;
  max-width: 436px;

  @media (max-width: ${TabletSize}) {
    max-width: unset;
    width: 100%;
  }
`;

const mapStateToProps = state => ({
  globalErrorMessage: getErrorMessage(state, 'createPartialDataAccount'),
  isLoading: isLoading(state, 'createPartialDataAccount'),
});

export default withRouter(
  connect(mapStateToProps)(PartialDataCreateAccountForm),
);
