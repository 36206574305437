import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { COLORS } from '../../helpers/constants/styleguide';

import { FlexColumn } from '../Flex';
import Icon, { SummerLogoSmall } from '../Icon';

const SummerLogoMedallion = ({ className, size = 43 }) => {
  return (
    <Circle size={size} className={className}>
      <Icon
        SvgComponent={SummerLogoSmall}
        fill={COLORS.lightBlue}
        width={size * 0.6}
        height={size * 0.6}
      />
    </Circle>
  );
};

const Circle = styled(FlexColumn).attrs({
  className: 'summer-logo-medallion',
})`
  border-radius: 50%;
  background-color: ${COLORS.white};
  height: ${props => props.size}px;
  width: ${props => props.size}px;
  min-width: ${props => props.size}px;
  justify-content: center;
  align-items: center;
`;

SummerLogoMedallion.propTypes = {
  size: PropTypes.number,
};

export default SummerLogoMedallion;
