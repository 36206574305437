import React from 'react';
import styled from 'styled-components';

import { FlexRow, Button, COLORS } from '@summer/ui-components';

import {
  xPaddingMobile as contentPaddingMobile,
  xPaddingDesktop as contentPaddingDesktop,
} from 'components/layout/wizard/WizardContent';
import { TabletSize } from 'constants/styleguide';
import { helpWidgetSize } from 'services/chat';

const WizardFooter = ({
  isThisLastStep,
  isBackDisabled,
  isBackLoading,
  handleBack,
  isNextDisabled,
  isNextFakeDisabled,
  isNextLoading,
  isNextVisible = true,
  handleSaveContinue,
  useSubmitLabel,
}) => {
  const getSubmitButtonLabel = () => {
    if (isThisLastStep) {
      return useSubmitLabel ? 'Submit' : 'Finish';
    }

    return 'Save & Continue';
  };

  return (
    <Container backButtonOnly={handleBack && !isNextVisible}>
      {handleBack && (
        <LeftContainer>
          <Button
            secondary
            width={120}
            disabled={isBackDisabled}
            isLoading={isBackLoading}
            onClick={handleBack}
          >
            Back
          </Button>
        </LeftContainer>
      )}
      {isNextVisible && (
        <RightContainer>
          <Button
            width={176}
            type="submit"
            disabled={isNextDisabled}
            isLoading={isNextLoading}
            onClick={handleSaveContinue}
            fakeDisabled={isNextFakeDisabled}
          >
            {getSubmitButtonLabel()}
          </Button>
        </RightContainer>
      )}
    </Container>
  );
};

const Container = styled(FlexRow)`
  justify-content: ${({ backButtonOnly }) =>
    backButtonOnly ? 'flex-start' : 'flex-end'};
  position: fixed;
  bottom: 0;
  margin-left: -${contentPaddingDesktop};
  width: 100%;
  padding: 16px;

  // keeps help widget from overlapping right button
  padding-right: calc(${helpWidgetSize.desktop} + 16px);

  @media (max-width: ${TabletSize}) {
    position: absolute;
    flex: 0 0 auto;
    // keeps help widget from overlapping right button
    padding-right: calc(${helpWidgetSize.mobile} + 16px);
    background-color: ${COLORS.white};
    margin-left: -${contentPaddingMobile};
  }

  &:before {
    content: '';
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${COLORS.white};
    opacity: 0.8;
  }

  &:after {
    content: '';
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
  }
`;

const LeftContainer = styled.div`
  & > button {
    height: 100%;
  }
`;

const RightContainer = styled(LeftContainer)`
  margin-left: 16px;
`;

export default WizardFooter;
