import React, { useCallback, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import styled from 'styled-components';

import {
  Certificate,
  IconMedallion,
  Button,
  FlexRow,
  Header,
  Text,
  SendEmail,
} from '@summer/ui-components';

import ModalPopup from 'components/shared/ModalPopup';
import { MobileSize } from 'constants/styleguide';
import { Apostrophes } from 'constants/text';
import { trackingEvents } from 'constants/trackingEvents';
import {
  TuitionReimbursementServiceTypes,
  TuitionReimbursementWizardSteps,
} from 'constants/tuitionReimbursement';
import { trackServerSide } from 'redux/actions/analytics.actions';
import { getTuitionReimbursementServiceType } from 'redux/selectors/tuitionReimbursement.selectors';
import { getTuitionReimbursementUi } from 'redux/selectors/ui.selectors';

const GetModalCopy = ({ completedStep, serviceType }) => {
  const getModalContent = () => {
    const defaultContent = {
      header:
        'Great! Your proof of completion and receipt have been submitted.',
      subHeader: `Here${Apostrophes}s what happens next:`,
      list: [
        'Summer will review and let you know if we need any additional information.',
        'If everything looks good, your employer will reimburse you in your paycheck.',
      ],
    };

    const contentMap = {
      [TuitionReimbursementServiceTypes.ASSISTANCE]: {
        [TuitionReimbursementWizardSteps.COURSE_REQUEST]: {
          header:
            'Great! Your educational course request has been submitted for approval.',
          subHeader: `Here${Apostrophes}s what happens next:`,
          list: [
            `Your request will be reviewed and you${Apostrophes}ll be informed if any other information is needed.`,
            'If the course is approved, you will receive tuition assistance from your employer so you can proceed with your course.',
          ],
        },
        [TuitionReimbursementWizardSteps.PROOF_OF_COMPLETION]: {
          header:
            'Great! Your proof of payment and completion have been submitted.',
          subHeader: `Here${Apostrophes}s what happens next:`,
          list: [
            `Your documents will be reviewed and you${Apostrophes}ll be informed if any additional information is required.`,
            'If approved, the process is complete!',
          ],
        },
      },
      [TuitionReimbursementServiceTypes.REIMBURSEMENT]: {
        [TuitionReimbursementWizardSteps.COURSE_REQUEST]: {
          header: 'Great! Your educational request has been sent for approval.',
          subHeader: `Here${Apostrophes}s what happens next:`,
          list: [
            `Your request will be reviewed and you${Apostrophes}ll be informed if any other information is needed.`,
            `If the course is approved, you can proceed with the course. You${Apostrophes}ll be eligible for reimbursement after completing the course and submitting your proof of completion and payment.`,
          ],
        },
        [TuitionReimbursementWizardSteps.PROOF_OF_COMPLETION]: {
          header:
            'Great! Your proof of completion and receipt have been submitted.',
          subHeader: `Here${Apostrophes}s what happens next:`,
          list: [
            `Your documents will be reviewed and you${Apostrophes}ll be informed if any additional information is required.`,
            'If everything looks good, your employer will receive the details to reimburse you.',
          ],
        },
      },
    };

    return contentMap[serviceType][completedStep] ?? defaultContent;
  };

  const { header, subHeader, list } = getModalContent();

  return (
    <>
      <Header as="h3">{header}</Header>
      <SubHeader>
        <Text bold>{subHeader}</Text>
      </SubHeader>
      <List>
        {list.map(item => (
          <li key={item}>{item}</li>
        ))}
      </List>
    </>
  );
};

const TuitionReimbursementCompleteModal = ({
  isOpen,
  togglePopup,
  tuitionReimbursementUi,
  tuitionReimbursementServiceType,
}) => {
  const dispatch = useDispatch();

  const { completedStep } = tuitionReimbursementUi;

  useEffect(() => {
    const viewEventsMap = {
      [TuitionReimbursementWizardSteps.COURSE_REQUEST]:
        trackingEvents.EDU_ASSIST[tuitionReimbursementServiceType]
          .COURSE_REQUEST_SUBMIT_MODAL_VIEWED,
      [TuitionReimbursementWizardSteps.PROOF_OF_COMPLETION]:
        trackingEvents.EDU_ASSIST[tuitionReimbursementServiceType]
          .ADD_PROOF_SUBMIT_MODAL_VIEWED,
    };

    if (isOpen && tuitionReimbursementServiceType) {
      dispatch(
        trackServerSide(viewEventsMap[completedStep], {
          integrations: { All: true },
        }),
      );
    }
  }, [completedStep, dispatch, tuitionReimbursementServiceType, isOpen]);

  const closeModal = useCallback(() => {
    const clickEventsMap = {
      [TuitionReimbursementWizardSteps.COURSE_REQUEST]:
        trackingEvents.EDU_ASSIST[tuitionReimbursementServiceType]
          .COURSE_REQUEST_SUBMIT_MODAL_CLICKED,
      [TuitionReimbursementWizardSteps.PROOF_OF_COMPLETION]:
        trackingEvents.EDU_ASSIST[tuitionReimbursementServiceType]
          .ADD_PROOF_SUBMIT_MODAL_CLICKED,
    };

    if (tuitionReimbursementServiceType) {
      dispatch(
        trackServerSide(clickEventsMap[completedStep], {
          integrations: { All: true },
        }),
      );
    }
    togglePopup();
  }, [completedStep, dispatch, togglePopup, tuitionReimbursementServiceType]);

  return (
    <ModalPopup
      isOpen={isOpen}
      togglePopup={togglePopup}
      width={700}
      component={() => {
        return (
          <Container>
            <IconRow>
              <IconMedallion
                SvgComponent={
                  completedStep ===
                  TuitionReimbursementWizardSteps.COURSE_REQUEST
                    ? SendEmail
                    : Certificate
                }
                size={75}
                paintWith="stroke"
              />
            </IconRow>
            <GetModalCopy
              serviceType={tuitionReimbursementServiceType}
              completedStep={completedStep}
            />

            <ButtonRow>
              <Button width={132} type="submit" onClick={closeModal}>
                Got it!
              </Button>
            </ButtonRow>
          </Container>
        );
      }}
    />
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 16px 24px 16px;
  @media (max-width: ${MobileSize}) {
    overflow: scroll;
    flex-direction: column;
    padding: 0;
  }
`;

const IconRow = styled(FlexRow)`
  justify-content: center;
  margin-bottom: 32px;

  & g {
    fill: none !important;
  }
`;

const ButtonRow = styled(FlexRow)`
  justify-content: flex-end;
`;

const SubHeader = styled.div`
  margin-top: 12px;
  margin-bottom: 20px;
`;

const List = styled.ul`
  margin-left: 16px;
  margin-bottom: 48px;

  & > li {
    margin-bottom: 16px;
  }
`;

const mapStateToProps = state => ({
  tuitionReimbursementUi: getTuitionReimbursementUi(state),
  tuitionReimbursementServiceType: getTuitionReimbursementServiceType(state),
});

export default connect(mapStateToProps)(TuitionReimbursementCompleteModal);
