import PropTypes from 'prop-types';
import React, { memo, useRef, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';

import { COLORS } from '@summer/ui-components';

import { trackLink } from 'utils/track';

/**
 * Send a track event to segment when the user clicks the link using the
 * trackLink function from analytics
 */
const useTrackLink = (track, href) => {
  const ref = useRef(null);
  useEffect(() => {
    if (track && ref.current && window.analytics) {
      trackLink(ref.current, 'User clicked link', {
        href,
        text: ref.current.text || ref.current.textContent,
      });
    }
  }, [ref, href, track]);

  return ref;
};

const Link = ({
  children,
  to = null,
  onClick = null,
  className = 'link',
  testId,
  track = false,
  inheritColor = false,
  underline = false,
  inheritWeight = false,
  ...props
}) => {
  let { href = null, target, rel } = props;

  const trackLinkRef = useTrackLink(track, href);

  let linkComponent = 'span';

  if (to) {
    href = undefined;
    linkComponent = RouterLink;
  } else if (href) {
    target = target || '_blank';
    rel = 'noopener noreferrer';
    linkComponent = 'a';
  }

  // Prevents Link focus styling when link has a Button child
  const tabIndex = children?.type?.name === 'Button' ? -1 : 0;

  return (
    <StyledLink
      className={className}
      ref={trackLinkRef}
      as={linkComponent}
      href={href}
      to={to}
      onClick={onClick}
      target={target}
      rel={rel}
      data-style={{ inheritColor, underline, inheritWeight }}
      data-testid={testId || `link-${children}`}
      tabIndex={tabIndex}
    >
      {children}
    </StyledLink>
  );
};

Link.propTypes = {
  /** Can be string or object: https://reacttraining.com/react-router/web/api/Link/to-string */
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  href: PropTypes.string,
  onClick: PropTypes.func,
  inheritColor: PropTypes.bool,
  underline: PropTypes.bool,
  inheritWeight: PropTypes.bool,
  track: PropTypes.bool,
};

const StyledLink = styled.span`
  color: ${props =>
    props['data-style'].inheritColor ? 'inherit' : COLORS.azure};
  font-size: inherit;
  font-weight: ${props => (props['data-style'].underline ? 'normal' : '600')};
  font-weight: ${props => props['data-style'].inheritWeight && 'inherit'};
  text-decoration: ${props =>
    props['data-style'].underline ? 'underline' : 'none'};
  cursor: pointer;
  user-select: none;
  &:hover {
    opacity: 0.8;
  }

  &:focus {
    outline: none;
  }

  &:focus-visible {
    outline: none;
    border: 1px solid transparent;
    border-radius: 4px;
    box-shadow: 0 0 0 2px ${COLORS.azure};
  }
`;

export const StorybookLink = Link;
export default memo(Link);
