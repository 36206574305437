import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Text } from '../../typography';
import { COLORS } from '../../../helpers/constants/styleguide';
import TooltipFloater from '../../TooltipFloater';

const getWidth = (text, width) => {
  if (width) {
    return width;
  }
  return text.length > 100 ? 300 : 240;
};

const TextTooltip = ({
  text = '',
  width,
  kind,
  track,
  children,
  lightUnderline,
}) => (
  <TooltipFloater
    track={track}
    kind={kind}
    placement="bottom"
    content={() => (
      <Text small paragraph>
        {text}
      </Text>
    )}
    width={getWidth(text, width)}
    offset={[0, 14]}
  >
    <Trigger lightUnderline={lightUnderline}>{children}</Trigger>
  </TooltipFloater>
);

TextTooltip.propTypes = {
  lightUnderline: PropTypes.bool,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  width: PropTypes.number,
  /** Used for mixpanel tracking */
  kind: PropTypes.string,
};

const Trigger = styled.span`
  cursor: pointer;
  font-size: inherit;

  border-bottom: ${({ lightUnderline }) => (lightUnderline ? 1 : 2)}px dashed
    ${COLORS.darkGreen};

  &:hover,
  .isOpen > & {
    opacity: 0.8;
  }
`;

export default TextTooltip;
