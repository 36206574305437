import {
  CHECK_USER,
  SET_USER,
  SET_VERIFICATION_TOKEN,
  VERIFY_USER_EMAIL,
  CHECK_EMAIL_IS_VERIFIED,
  KEEP_SESSION_ALIVE,
  SET_NYC_SSO_POC_RESPONSE,
} from 'constants/actionTypes';

export const checkUser = () => ({
  type: CHECK_USER,
});

export const verifyUser = ({ token }) => ({
  type: VERIFY_USER_EMAIL,
  payload: {
    token,
  },
});

export const checkEmailIsVerified = () => ({
  type: CHECK_EMAIL_IS_VERIFIED,
});

export const setUser = (user, meta) => ({
  type: SET_USER,
  payload: { ...user },
  meta,
});

export const setVerificationToken = token => ({
  type: SET_VERIFICATION_TOKEN,
  payload: { token },
});

export const keepSessionAlive = () => ({
  type: KEEP_SESSION_ALIVE,
});

export const setNycSsoPocResponse = response => ({
  type: SET_NYC_SSO_POC_RESPONSE,
  payload: response,
});
