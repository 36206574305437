import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { isFunction } from 'lodash';

import { hexToRgba } from '../../helpers/utils/common';

import { COLORS, MobileSize } from '../../helpers/constants/styleguide';
import LoadingBars from '../LoadingBars';

const Button = ({
  children = '',
  disabled = false,
  fakeDisabled = false,
  fullWidth = false,
  borderless = false,
  innerRef = null,
  isLoading = false,
  onClick = null,
  secondary = false,
  type = 'button',
  width = 360,
  className,
  track,
  kind,
  backgroundColor,
  textColor,
  textSize,
}) => {
  const clickHandler = e => {
    if (isFunction(track)) {
      track('User clicked button', {
        button: kind || children,
      });
    }
    if (isFunction(onClick)) {
      onClick(e);
    }
  };

  return (
    <StyledButton
      className={className}
      ref={innerRef}
      type={type}
      width={width}
      fullWidth={fullWidth}
      borderless={borderless}
      disabled={disabled || isLoading}
      fakeDisabled={fakeDisabled}
      onClick={disabled || isLoading ? null : clickHandler}
      secondary={secondary}
      data-testid={`button-${type}-${kind || children}`}
      backgroundColor={backgroundColor}
      textColor={textColor}
    >
      {isLoading ? (
        <LoadingBars color={secondary ? COLORS.medGrey : null} />
      ) : (
        <Text
          secondary={secondary}
          disabled={disabled || fakeDisabled}
          textSize={textSize}
        >
          {children}
        </Text>
      )}
    </StyledButton>
  );
};

Button.propTypes = {
  /** Button text */
  children: PropTypes.node.isRequired,
  /** Click handler */
  onClick: PropTypes.func,
  /** HTML type attribute */
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  disabled: PropTypes.bool,
  fakeDisabled: PropTypes.bool,
  /** Determines if the button should be full width on non-mobile widths */
  fullWidth: PropTypes.bool,
  /** Allows you to remove border to create a link-styled button */
  borderless: PropTypes.bool,
  isLoading: PropTypes.bool,
  secondary: PropTypes.bool,
  width: PropTypes.number,
  innerRef: PropTypes.func,
  /** Track function from analytics */
  track: PropTypes.func,
  /** String used for tracking */
  kind: PropTypes.string,
  backgroundColor: PropTypes.string,
  textColor: PropTypes.string,
};

const getColor = (propColor, disabled, white) => {
  if (propColor) {
    return propColor;
  }
  if (white) {
    return COLORS.white;
  }
  return disabled ? COLORS.medGrey : COLORS.azure;
};

const StyledButton = styled.button.attrs(props => ({
  className: (props.disabled || props.fakeDisabled) && 'disabled',
}))`
  border: ${props =>
    props.borderless
      ? '0'
      : `1px solid ${getColor(
          props.backgroundColor,
          props.disabled || props.fakeDisabled,
          false,
        )}`};
  width: ${props => (props.fullWidth ? '100%' : `${props.width}px`)};
  min-width: 100px;
  padding: 14px 8px;
  user-select: none;

  @media (max-width: ${MobileSize}) {
    width: 100%;
  }

  background-color: ${({
    backgroundColor,
    disabled,
    fakeDisabled,
    secondary,
  }) => {
    return getColor(backgroundColor, disabled || fakeDisabled, secondary);
  }};

  border-radius: 3px;
  cursor: ${props =>
    props.disabled || props.fakeDisabled ? 'default' : 'pointer'};

  &:hover {
    opacity: ${props => !props.disabled && !props.fakeDisabled && '0.8'};
  }

  &:focus {
    outline: none;
  }

  &:focus-visible {
    ${({ disabled, fakeDisabled }) => {
      if (!disabled && !fakeDisabled) {
        return `box-shadow: 0 0 0 2.5px ${COLORS.white}, 0px 0px 0px 4px ${COLORS.azure};`;
      }
      return ``;
    }}
  }

  &:active {
    ${({ backgroundColor, disabled, fakeDisabled, secondary }) => {
      if (disabled || fakeDisabled) {
        return `background-color: ${getColor(
          backgroundColor,
          disabled || fakeDisabled,
          secondary,
        )};`;
      }

      const color = getColor(backgroundColor, false, false);
      return secondary
        ? `background-color: ${hexToRgba(color, 0.2)};`
        : `border: 1px solid ${hexToRgba(color, 0.1)};
        background-color: ${hexToRgba(color, 0.7)};`;
    }}
  }
`;

const Text = styled.div`
  font-size: ${({ textSize }) => textSize ?? '16px'};
  font-weight: 600;
  width: 100%;
  color: ${({ textColor, disabled, secondary }) =>
    getColor(textColor, disabled, !secondary)};
  opacity: ${props => props.disabled && '0.7'};
`;

export default Button;
