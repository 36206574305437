import React from 'react';
import styled from 'styled-components';

import { Header, InfoTooltip, Text } from '@summer/ui-components';

import { arrayToOptions } from 'utils/toOptions';

import {
  GridContainer,
  NumberInputCSP,
  PercentageInputCSP,
  RadioButtonsCSP,
} from '../shared';

import EnterCollegeCost from './EnterCollegeCost';
import SearchByCollegeName from './SearchByCollegeName';
import SelectCollegeType from './SelectCollegeType';

export const collegeCostMethods = {
  'Search by college name': SearchByCollegeName,
  'Select college type': SelectCollegeType,
  'Enter cost manually': EnterCollegeCost,
};

const CollegeInformation = ({ formik }) => {
  const selectedMethod = formik.values.collegeCostMethod;

  const CollegeCostComponent = collegeCostMethods[selectedMethod];

  return (
    <>
      <Header h3>School Information</Header>
      <Text bold>
        First, let&rsquo;s estimate what the cost of attendance will be for your
        school. You can select any of these options:
      </Text>
      <RadioButtonsCSP
        name="collegeCostMethod"
        options={arrayToOptions(Object.keys(collegeCostMethods))}
        formik={formik}
        defaultCol={3}
      />
      {selectedMethod && (
        <>
          <CollegeCostComponent formik={formik} />

          <StyledGridContainer>
            <NumberInputCSP
              name="startYear"
              label="What year do you or the student expect to start college?"
              placeholder="Enter a year (e.g. 2045)"
              limit={4}
              formik={formik}
            />
            <NumberInputCSP
              name="attendanceDuration"
              label="How many years do you or the student expect to attend?"
              placeholder="Enter number of years (e.g. 4)"
              limit={1}
              formik={formik}
            />
            <PercentageInputCSP
              name="costIncrease"
              label={
                <>
                  College cost inflation rate{' '}
                  <InfoTooltip text="The price of college tends to go up every year, so this is how we estimate the full cost over time. Our estimate is based on aggregate data, but you can enter your own estimate." />
                </>
              }
              formik={formik}
            />
          </StyledGridContainer>
        </>
      )}
    </>
  );
};

const StyledGridContainer = styled(GridContainer)`
  margin-top: 16px;
`;

export default CollegeInformation;
