import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import {
  Icon,
  Calculator,
  Text,
  Header,
  FlexCenter,
  Button,
  COLORS,
} from '@summer/ui-components';

import BrowserModal from 'components/common/BrowserModal';
import MultiToolWelcomeContent from 'components/pages/onboarding/Welcome/MultiToolWelcome';
import { finish } from 'constants/onboardingSteps';
import { trackingEvents } from 'constants/trackingEvents';
import { useIsPageLoading } from 'hooks/common';
import { useUserShouldSkipOnboarding } from 'hooks/shared';
import { trackServerSide } from 'redux/actions/analytics.actions';
import {
  finishWelcome,
  persistOnboardingStep,
  setOnboardingStep,
} from 'redux/actions/onboard.actions';
import { hydrateUser } from 'redux/actions/user.actions';
import {
  getIsMultiToolWelcomeEnabled,
  getIsNewCSPStartPageEnabled,
  getIsRetirementMatchV1Enabled,
  getIsContributionsEnabled,
  getIsTuitionReimbursementEnabled,
} from 'redux/selectors/flags.selectors';
import { getOnboardingCurrentStep } from 'redux/selectors/onboarding.selectors';
import { isLoading } from 'redux/selectors/ui.selectors';

const Welcome = ({
  finishWelcome,
  currentStep,
  userHasSLC,
  userHasRetirementMatch,
  hydrateUser,
  isLoadingHydrateUser,
  showMultiToolWelcomePage,
  showNewCSPStartPage,
  isTuitionReimbursementEnabled,
}) => {
  const { push } = useHistory();
  const dispatch = useDispatch();
  const isHydrateUserLoading = useIsPageLoading(isLoadingHydrateUser);
  const userShouldSkipOnboarding = useUserShouldSkipOnboarding();

  const finishedLoadingSkipActions = !isHydrateUserLoading;

  const isReadyToSkip = userHasSLC || finishedLoadingSkipActions;

  useEffect(() => {
    if (isTuitionReimbursementEnabled) {
      dispatch(
        trackServerSide(trackingEvents.ONBOARDING_PAGE_VIEWED, {
          integrations: { All: true },
        }),
      );
    }
  }, [isTuitionReimbursementEnabled, dispatch]);

  useEffect(
    function skipOnboarding() {
      if (
        userShouldSkipOnboarding &&
        isReadyToSkip &&
        !showMultiToolWelcomePage
      ) {
        let location;
        switch (true) {
          case userHasSLC:
            location = '/employer-contribution';
            break;
          case userHasRetirementMatch:
            location = '/retirement-match/dashboard';
            break;
          default:
            location = undefined;
        }

        dispatch(setOnboardingStep(finish));
        dispatch(persistOnboardingStep(finish));

        push(location);
      }
    },
    [
      userHasSLC,
      userShouldSkipOnboarding,
      push,
      currentStep,
      dispatch,
      isHydrateUserLoading,
      isReadyToSkip,
      userHasRetirementMatch,
      showMultiToolWelcomePage,
    ],
  );

  useEffect(
    function hydrateUserOnMount() {
      hydrateUser();
    },
    [hydrateUser],
  );

  // TODO: improve rendering logic [sc-32904]
  if (!isReadyToSkip) {
    return null;
  }

  return (
    <>
      {showMultiToolWelcomePage ? (
        <MultiToolWelcomeContent
          proceedToRepayment={finishWelcome}
          proceedToRetirement={() => {
            dispatch(setOnboardingStep(finish));
            dispatch(persistOnboardingStep(finish));
            push('/retirement-match/dashboard');
          }}
          proceedToCSP={() => {
            dispatch(setOnboardingStep(finish));
            dispatch(persistOnboardingStep(finish));
            push(
              showNewCSPStartPage
                ? '/college-savings-planner'
                : '/college-savings-planner/calculator',
            );
          }}
          proceedToTuitionReimbursement={() => {
            dispatch(setOnboardingStep(finish));
            dispatch(persistOnboardingStep(finish));
            push('/educational-assistance');
          }}
          proceedToStudentLoanContribution={() => {
            dispatch(setOnboardingStep(finish));
            dispatch(persistOnboardingStep(finish));
            push('/employer-contribution');
          }}
          proceedToEmergencySavings={() => {
            dispatch(setOnboardingStep(finish));
            dispatch(persistOnboardingStep(finish));
            push('/emergency-savings');
          }}
        />
      ) : (
        <WelcomeContainer direction="column">
          <StyledIcon
            fill={COLORS.azure}
            width={40}
            SvgComponent={Calculator}
          />

          <Title>
            <Header h2 center>
              Let&rsquo;s find out how much less you could pay each month.
            </Header>
          </Title>

          <TextContainer>
            <Text center paragraph>
              Answering a few questions will help us customize our tools for
              your situation.
            </Text>
          </TextContainer>

          <Button onClick={finishWelcome} width={272}>
            Let’s get started
          </Button>
        </WelcomeContainer>
      )}
      <BrowserModal />
    </>
  );
};

const WelcomeContainer = styled(FlexCenter)`
  flex-shrink: 0;
  max-width: 280px;
`;

const Title = styled.div`
  margin-bottom: 10px;
`;

const TextContainer = styled.div`
  margin-bottom: 30px;
`;

const StyledIcon = styled(Icon)`
  width: 50px;
  height: 50px;
  margin-bottom: 20px;
`;

const mapStateToProps = state => ({
  currentStep: getOnboardingCurrentStep(state),
  userHasSLC: getIsContributionsEnabled(state),
  userHasRetirementMatch: getIsRetirementMatchV1Enabled(state),
  isLoadingHydrateUser: isLoading(state, 'hydrateUser'),
  showMultiToolWelcomePage: getIsMultiToolWelcomeEnabled(state),
  showNewCSPStartPage: getIsNewCSPStartPageEnabled(state),
  isTuitionReimbursementEnabled: getIsTuitionReimbursementEnabled(state),
});

export default connect(mapStateToProps, {
  finishWelcome,
  hydrateUser,
})(Welcome);
